import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from 'react-toastify';

import { Card, Col, Row, Table, Tabs, Typography } from 'antd';

import Breadcrumb from '../../Common/Breadcrumb';
import usecustomStyles from '../../Common/customStyles';
import { bootData } from "../../config";
import Spinners from '../../Common/Spinner';

import { getSettings } from "../../slices/thunk";

const customStyles = usecustomStyles();
const { Text } = Typography;

const SettingsPage = () => {
  document.title = "Settings-" + bootData.settings.appTitle;

  const dispatch = useDispatch();

  const selectSettings = createSelector(
    (state) => state.SettingsStats,
    (settingsstats) => ({
      settings: settingsstats.settings,
      loading: settingsstats.loading,
    })
  );
  const { settings, loading } = useSelector(selectSettings);
  const tabs = Object.keys(settings);
  const chunkArray = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  };

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  //Table Columns
  const columns = [
    {
      title: 'Configuration',
      render: (_, record) => {
        if (record.length > 0) {
          return (<Text type="secondary" >{record[0]}</Text>);
        }
        return (<></>);
      },
    },
    {
      title: 'Value',
      render: (_, record) => {
        if (record.length > 1) {
          return (<Text type="secondary" >{record[1]}</Text>);
        }
        return (<></>);
      },
    }
  ];

  //Tab Items
  let items = [];
  tabs.forEach((tab, index) => {
    const chunkedRows = chunkArray(Object.entries(settings[tab]), 9);

    items.push({
      key: (index + 1).toString(),
      label: <span>{tab}</span>,
      children: (
        <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          <Row className="d-flex"  style={{ display: 'flex', flexWrap: 'nowrap' }}>
            {
              chunkedRows.map((chunk, columnIndex) => (
                  <Col key={columnIndex}>
                    <Card style={{ marginBottom: customStyles.margin, marginRight: customStyles.margin, height: '100%' }}>
                      <div style={{overflowX:'auto', whiteSpace:'nowrap'}}>
                        <Table columns={columns} dataSource={chunk} 
                          pagination={{
                            defaultCurrent: 1,
                            pageSize: chunk.length,
                            total: chunk.length,
                            hideOnSinglePage: true,
                          }}
                        />
                      </div>
                    </Card>
                  </Col>
              ))
            }
          </Row>
        </div>
      ),
    });
  });

  return (
    <React.Fragment>
      <Breadcrumb mainTitle="Administration" pageTitle="Settings" />
      <Row gutter={[24]}>
        <Col lg={24} xl={24}>
          <Card style={{ marginBottom: customStyles.margin }}>
            <div style={{ display: "flex", alignItems: "center" }}> 
              <Text type="primary" >These system settings are defined in ini files (or overridden in ENV variables). To change these you currently need to restart Dashboard.</Text>
            </div>
          </Card>
        </Col>
      </Row>
      {
        loading ? <Spinners/>
      :
        <Tabs defaultActiveKey="1" items={items} />
      }
      <ToastContainer />
    </React.Fragment>
  )
}

export default SettingsPage;