import { getFakeOrgs, removeFakeOrg, createFakeOrg } from "../../../helpers/fakebackend_helper";
import { orgsSuccess, setLoading } from "./reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getOrgs = (query) => async (dispatch) => {
    try {
        let response;
        dispatch(setLoading(true));
        response = getFakeOrgs(query);
        const data = await response;
        if (data) {
          dispatch(orgsSuccess(data));
        }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to get orgs: " + error.message, { autoClose: 3000 });
    }
};

export const removeOrg = (id) => async (dispatch) => {
  try {
      let response;
      response = removeFakeOrg(id);
      const data = await response;
      const query = {page: 1, perpage: 10, query: ''};
      dispatch(getOrgs(query));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to remove org: " + error.message, { autoClose: 3000 });
  }
}

export const createOrg = (values) => async (dispatch) => {
  try {
      let response;
      response = createFakeOrg(values);
      const data = await response;
      const query = {page: 1, perpage: 10, query: ''};
      dispatch(getOrgs(query));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to create org: " + error.message, { autoClose: 3000 });
  }
}