import { getFakeUser, getFakeUserOrgs, getFakeUserAuthTokens, revokeFakeUserSession, revokeFakeUserAllSessions, updateFakeUser, updateFakeUserPassword, updateFakeUserPermission, updateFakeUserOrgRole, removeFakeUserFromOrg, addFakeOrgUser } from "../../../helpers/fakebackend_helper";
import { userSuccess, userOrgsSuccess, userAuthTokensSuccess,  setLoading } from "./reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getUser = (id) => async (dispatch) => {
    try {
        let response;
        dispatch(setLoading(true));
        response = getFakeUser(id);
        const data = await response;
        if (data) {
          dispatch(userSuccess(data));
        }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to get user: " + error.message, { autoClose: 3000 });
    }
};

export const getUserOrgs = (id) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = getFakeUserOrgs(id);
      const data = await response;
      if (data) {
        dispatch(userOrgsSuccess(data));
      }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get user orgs: " + error.message, { autoClose: 3000 });
  }
};

export const getUserAuthTokens = (id) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = getFakeUserAuthTokens(id);
      const data = await response;
      if (data) {
        dispatch(userAuthTokensSuccess(data));
      }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get user auth tokens: " + error.message, { autoClose: 3000 });
  }
};

export const revokeUserSession = (query) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = revokeFakeUserSession(query);
      const data = await response;
      dispatch(getUserAuthTokens(query.id));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to revoke user session: " + error.message, { autoClose: 3000 });
  }
};

export const revokeUserAllSessions = (id) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = revokeFakeUserAllSessions(id);
      const data = await response;
      dispatch(getUserAuthTokens(id));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to revoke user all sessions: " + error.message, { autoClose: 3000 });
  }
};

export const updateUser = (values) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = updateFakeUser(values);
      const data = await response;
      dispatch(getUser(values.id));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update user: " + error.message, { autoClose: 3000 });
  }
};

export const updateUserPassword = (values) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = updateFakeUserPassword(values);
      const data = await response;
      dispatch(getUser(values.id));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update user password: " + error.message, { autoClose: 3000 });
  }
};

export const updateUserPermission = (values) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = updateFakeUserPermission(values);
      const data = await response;
      dispatch(getUser(values.id));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update user permission: " + error.message, { autoClose: 3000 });
  }
};

export const updateUserOrgRole = (values) => async (dispatch) => {  
  try {
      let response;
      dispatch(setLoading(true));
      response = updateFakeUserOrgRole(values);
      const data = await response;
      dispatch(getUserOrgs(values.userId));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update user org role: " + error.message, { autoClose: 3000 });
  }
}

export const removedUserFromOrg = (values) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = removeFakeUserFromOrg(values);
      const data = await response;
      dispatch(getUserOrgs(values.userId));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to remove user from org: " + error.message, { autoClose: 3000 });
  }
}

export const addUserToOrg = (values) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = addFakeOrgUser(values);
      const data = await response;
      dispatch(getUserOrgs(values.userId));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to add user to org: " + error.message, { autoClose: 3000 });
  }
}