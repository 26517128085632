import { postFakeForgotPassword, postFakeResetPassword } from "../../../helpers/fakebackend_helper";
import { setLoading, resetPasswordSuccess } from "./reducer"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const forgotPassword = (values) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = postFakeForgotPassword(values)
    const data = await response;
    dispatch(setLoading(false));
    if (data) {
      toast.success(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to sent to reset link to your mailbox: " + error.message, { autoClose: 3000 });
  }
}

export const resetPassword = (values) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = postFakeResetPassword(values);
    const data = await response;
    dispatch(resetPasswordSuccess());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to change password: " + error.message, { autoClose: 3000 });
  }
};