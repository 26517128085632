import { debounce } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from 'react-toastify';

import { Button, Card, Col, Input, Row, Popconfirm, Space, Table, Form, Modal } from 'antd';
import { Pen, Search, Trash2, Building } from 'lucide-react';

import Breadcrumb from '../../Common/Breadcrumb';
import usecustomStyles from '../../Common/customStyles';
import { bootData } from "../../config";
import Spinners from '../../Common/Spinner';

import { getOrgs, removeOrg, createOrg } from "../../slices/thunk";

const customStyles = usecustomStyles();

const OrgsPage = () => {
    document.title = "Orgs-" + bootData.settings.appTitle;

    const dispatch = useDispatch();
    const [search, setSearch] = useState('');

    const selectOrgs = createSelector(
      (state) => state.Orgs,
      (orgs) => ({
        orgs: orgs.orgs,
        loading: orgs.loading,
        totalCount: orgs.totalOrgsCount,
        perPage: orgs.perPage,
        page: orgs.page,
      })
    );
    const { orgs, loading, totalCount, perPage, page } = useSelector(selectOrgs);

    useEffect(() => {
      const query = {page: 1, perpage: 10, query: ''};
        dispatch(getOrgs(query));
    }, [dispatch]);

    // Helper Functions
    const onPagination = (page, pageSize) => {
      const query = {page: page, perpage: pageSize, query: search};
      dispatch(getOrgs(query));
    }
    const searchOrgs = (e) => {
      setSearch(e.target.value);
      const query = {page: 1, perpage: 10, query: e.target.value};
      dispatch(getOrgs(query));
    }
    const delayedSearchOrg = debounce((e) => searchOrgs(e), 500);

    //Modal
    const [createOrgModalShow, setCreateOrgModalShow] = useState(false)
    const toggleCreateOrgModal = useCallback(() => (createOrgModalShow)? setCreateOrgModalShow(false): setCreateOrgModalShow(true), [createOrgModalShow]);

    //Initialise Forms
    const [createOrgForm] = Form.useForm();
    useEffect(() => {
      createOrgForm.setFieldsValue({
        name: '',
      });
    }, [createOrgForm]);
    
    //Submit Forms
    const handleCreateOrgSubmit = (values) => {
      dispatch(createOrg(values));
      createOrgForm.resetFields();
      toggleCreateOrgModal();
    }

    //Table Columns
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Actions',
        align: 'center',
        render: (_, record) => (
          <Space>
            <Button href={`/admin/orgs/${record.id}`} icon={<Pen size={12} />} size="small" />
            <Popconfirm title="Remove org" description="Are you sure to remove?" onConfirm={() => dispatch(removeOrg(record.id))}  okText="Remove" cancelText="Cancel">
              <Button icon={<Trash2 size={12} />} size="small" danger/>
            </Popconfirm>
          </Space>
        )
      },
    ];

    return (
        <React.Fragment>
            <Breadcrumb mainTitle="Administration" pageTitle="Orgs" />
            <Row gutter={[24]}>
                <Col lg={24} xl={24}>
                    <Card style={{ marginBottom: customStyles.margin }} >
                        <div style={{ display: "flex", justifyContent: "space-between", gap: '10px' }}>
                            <div className="search-box" style={{ position: "relative", display: "flex", width: '100%' }}>
                              <Input type="text" allowClear placeholder="Search for name" style={{ paddingLeft: "40px" }}
                                onChange={(e) => delayedSearchOrg(e)}
                              />
                              <Search style={{ position: "absolute", left: "10px", top: "10px" }} size={14} />
                            </div>
                            <div>
                              <Button type='primary' onClick={toggleCreateOrgModal} style={{ backgroundColor: customStyles.colorPrimary, boxShadow:'none' }}>
                                <Building size={16} />Create Org
                              </Button>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            {
              loading ? <Spinners/>
              : 
              <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}>
                <Table columns={columns} dataSource={(orgs || []).map((org, index) => ({
                      ...org,
                      key: index,
                  }))}
                  pagination={{
                    current: page,
                    pageSize: perPage,
                    defaultCurrent: 1,
                    total: totalCount,
                    onChange: onPagination,
                    hideOnSinglePage: true
                  }}
                />
              </div>
            }
            <ToastContainer />
            <Modal centered title="Add User" open={createOrgModalShow} onCancel={toggleCreateOrgModal} footer={[]}>
              <Form  form={createOrgForm} name="add-user" onFinish={handleCreateOrgSubmit}>
                <div>
                  <label style={{ display: 'block', marginBottom: '4px' }}>Name</label>
                  <Form.Item name="name" rules={[{ required: true, message: 'Enter Name' }]}>
                    <Input name="name" type="text" placeholder="Enter Name" style={{boxShadow: 'none'}}/>
                  </Form.Item>
                </div>
                <div style={{ display:'flex', gap: '10px', justifyContent: "end", marginTop: customStyles.margin }}>
                  <Form.Item>
                    <Button type="link" danger onClick={() => toggleCreateOrgModal()}>Close</Button>
                    <Button htmlType="submit" style={{ backgroundColor: customStyles.colorInfo, color: "white" }}>Invite</Button>
                  </Form.Item>
                </div>
              </Form>
            </Modal>
        </React.Fragment>
    )
}

export default OrgsPage