import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  settings: {},
  stats: {},
};

const settingsSlice = createSlice({
  name: "SettingsStats",
  initialState,
  reducers: {
    settingsSuccess(state, action) {
      state.settings = action.payload
      state.loading = false
    },
    statsSuccess(state, action) {
      state.stats = action.payload
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
});

export const {
  settingsSuccess,
  statsSuccess,
  setLoading,
} = settingsSlice.actions

export default settingsSlice.reducer;