import { debounce } from 'lodash';
import React, { useEffect, useState, useCallback } from "react";
import {useAsyncFn} from 'react-use';
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from 'react-toastify';
import { createSelector } from "reselect";

// actions
import { getFakeOrgs } from "../../helpers/fakebackend_helper";

import { Card, Col, Form, Row, Typography, Tabs, Popconfirm, Input, Button, Modal, Space, Table, Radio, Select } from "antd";
import { MinusOutlined, SafetyOutlined, LogoutOutlined, UserAddOutlined } from '@ant-design/icons';
import { Trash2 } from 'lucide-react';

import Breadcrumb from "../../Common/Breadcrumb";
import usecustomStyles from "../../Common/customStyles";
import withRouter from "../../Common/withRouter";
import { bootData } from "../../config";
import Spinners from '../../Common/Spinner';
import { roles, formatDateTime } from "../../Common/data";

import { getUser, getUserOrgs, getUserAuthTokens,
         updateUser, updateUserPassword, updateUserPermission,
         updateUserOrgRole, removedUserFromOrg, revokeUserSession,
         revokeUserAllSessions, addUserToOrg } from "../../slices/thunk";

const customStyles = usecustomStyles();
const { Text } = Typography;

const UserPage = (props) => {
  document.title = "User-" + bootData.settings.appTitle;
  let { id } = props.router.params;
  const dispatch = useDispatch();
  const [userOrg, setUserOrg] = useState({});

  const selectUser = createSelector(
    (state) => state.User,
    (user) => ({
      user: user.user,
      userOrgs: user.userOrgs,
      userAuthTokens: user.userAuthTokens,
      loading: user.loading,
    })
  );
  const { user, userOrgs, userAuthTokens, loading } = useSelector(selectUser);

  useEffect(() => {
    dispatch(getUser(id));
    dispatch(getUserOrgs(id));
    dispatch(getUserAuthTokens(id));
}, [dispatch, id]);

  //Intialize Forms
  const [userForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [orgForm] = Form.useForm();
  const [roleForm] = Form.useForm();
  useEffect(() => {
    roleForm.setFieldsValue({
      role: userOrg.role || 'Viewer',
    });
  }, [roleForm, userOrg]);
  useEffect(() => {
    userForm.setFieldsValue({
      login: user.login || "",
      email: user.email || "",
      name: user.name || "",
    });
  }, [userForm, user]);
  useEffect(() => {
    passwordForm.setFieldsValue({
      password: "",
    });
  }, [passwordForm]);
  useEffect(() => {
    orgForm.setFieldsValue({
      role: 'Viewer',
    });
  }, [orgForm]);

  //Set models
  const [changePasswordModalShow, setChangePasswordModalShow] = useState(false);
  const [addOrgModalShow, setAddOrgModalShow] = useState(false);
  const [updateOrgRoleModalShow, setUpdateOrgRoleModalShow] = useState(false);
  const setOrgForRoleUpdate = useCallback((org) => {
    setUpdateOrgRoleModalShow(!updateOrgRoleModalShow);
    setUserOrg(org);
  }, [updateOrgRoleModalShow]);

  //Set orgs lookup
  const [orgsOption, setOrgsOption] = useState([]);
  const [getOrgsState, getOrgs] = useAsyncFn(async (q) => {
    const query = {page: 1, perpage: 100, query: q};
    const response = await getFakeOrgs(query);
    const allOrgs = response.orgs.map((org) => ({ value: org.id, label: org.name }));
    if (userOrgs && userOrgs.length > 0) {
      const orgIds = userOrgs.map((org) => org.orgId);
      setOrgsOption(allOrgs.filter((org) => !orgIds.includes(org.value)));
      return;
    }
    setOrgsOption(allOrgs);
    return;
  }, [userOrgs]);

  useEffect(() => {
    getOrgs('');
  }, [userOrgs, getOrgs]);

  //Submit Forms
  const updateUserSubmit = (values) => {
    dispatch(updateUser({ ...values, id: id }));
  };
  const updatePasswordSubmit = (values) => {
    dispatch(updateUserPassword({ ...values, id: id }));
    passwordForm.resetFields();
    setChangePasswordModalShow(!changePasswordModalShow);
  };
  const handleAddOrgSubmit = (values) => {
    dispatch(addUserToOrg({ ...values, userId: id, loginOrEmail: user.login }));
    setAddOrgModalShow(!addOrgModalShow);
    orgForm.resetFields();
  };
  const handleOrgRoleSubmit = (values) => {
    dispatch(updateUserOrgRole({...values, userId: id, orgId: userOrg.orgId}));
    setOrgForRoleUpdate({});
    roleForm.resetFields();
  }

  const delayedGetOrgs = debounce(getOrgs, 300, { leading: true, trailing: true});

  //Table User Orgs Columns
  const orgColumns = [
    {
      align: 'center',
      render: (_, record) => (
        <img src={bootData.user.gravatarUrl} alt='' height={36} style={{ borderRadius: "50%" }}></img>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => setOrgForRoleUpdate(record)} size="small">Change Role</Button>
          <Popconfirm title="Remove from organization" description="Are you sure to remove?" onConfirm={() => {dispatch(removedUserFromOrg({orgId: record.orgId, userId: id}))}}  okText="Remove" cancelText="Cancel">
            <Button icon={<Trash2 size={12} />} size="small" danger/>
          </Popconfirm>
        </Space>
      )
    },
  ];

  //Table User Auth Tokens Columns
  const authTokensColumns = [
    {
      title: 'Last seen',
      align: 'center',
      dataIndex: 'seenAt',
      render: (text, record) => {
        if (record.isActive) {
          return (<span>Now</span>);
        }
        return (<span>{formatDateTime(text)}</span>);
      }
    },
    {
      title: 'Logged on',
      align: 'center',
      dataIndex: 'createdAt',
      render: (text) => (
        <span>{formatDateTime(text)}</span>
      )
    },
    {
      title: 'IP address',
      dataIndex: 'clientIp',
      sorter: (a, b) => a.clientIp.localeCompare(b.clientIp),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Browser and OS',
      render: (_, record) => (
        <span>{`${record.browser} on ${record.os} ${record.osVersion}`}</span>
      )
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_, record) => (
        <Popconfirm title="Logout" description="Are you sure to logout?" onConfirm={() => {dispatch(revokeUserSession({id: id, tokenId: record.id}))}}  okText="Logout" cancelText="Cancel">
          <Button icon={<LogoutOutlined size={12} />} size="small" danger/>
        </Popconfirm>
      )
    },
  ];

  //Tab Items
  const items = [
    {
      key: "1",
      label: <span>User Information</span>,
      children: (
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }}>
              <Form layout={'horizontal'} labelCol={{flex: '200px'}} wrapperCol={{flex: 1}} style={{ maxWidth: 'none' }} form={userForm} name="update-user" onFinish={updateUserSubmit}>
                <div>
                  <Form.Item label="Name" name="name" rules={[{ required: true, message: "Enter Name" }]}>
                    <Input name="name" type="text" placeholder="Enter Name" style={{ boxShadow: "none" }} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item label="User Name" name="login" rules={[{ required: true, message: "Enter UserName" }]} >
                    <Input name="login" type="text" placeholder="Enter UserName" style={{ boxShadow: "none" }}/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item label="Email" name="email" rules={[{ required: true, message: "Email is required" }]} >
                    <Input name="email" type="email" placeholder="Enter Email" style={{ boxShadow: "none" }}/>
                  </Form.Item>
                </div>
                <div style={{ display:'flex', gap: '10px', justifyContent: "center" }}>
                  <Form.Item>
                    <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white"}} >Update</Button>
                  </Form.Item>
                  <Form.Item>
                    <Button onClick={() => setChangePasswordModalShow(!changePasswordModalShow)} style={{ backgroundColor: customStyles.colorPrimary, color: "white"}} >Change Password</Button>
                  </Form.Item>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: <span>Organizations</span>,
      children: (
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }} >
              <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: customStyles.margin }}>
                <Button type='primary' onClick={() => setAddOrgModalShow(!addOrgModalShow)} style={{ backgroundColor: customStyles.colorInfo, boxShadow:'none' }}>
                  <UserAddOutlined />Add user to organization
                </Button>
              </div>
              {
                loading ? <Spinners/>
              :
                <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}>
                  <Table columns={orgColumns} dataSource={(userOrgs || []).map((org, index) => ({ ...org,  key: index }))}
                    pagination={{ pageSize: 10, total: userOrgs.length, hideOnSinglePage: true }} />
                </div>
              }
            </Card>
          </Col>
        </Row>
      ),
    },
    {
      key: "3",
      label: <span>Sessions</span>,
      children: (
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }} >
              <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: customStyles.margin }}>
                <Popconfirm title="Logout" description="Are you sure to logout?" onConfirm={() => {dispatch(revokeUserAllSessions(id))}}  okText="Logout" cancelText="Cancel">
                  <Button type='primary' style={{ boxShadow:'none' }} danger><LogoutOutlined />Logout from all devices</Button>
                </Popconfirm>
              </div>
              {
                loading ? <Spinners/>
              :
                <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}> 
                  <Table columns={authTokensColumns} dataSource={(userAuthTokens || []).map((session, index) => ({ ...session, key: index }))}
                    pagination={{ pageSize: 10, total: userAuthTokens.length, hideOnSinglePage: true }}
                  />
                </div>
              }
            </Card>
          </Col>
        </Row>
      ),
    },
  ];

  const color = user.isSuperAdmin ? customStyles.colorInfo: customStyles.colorDanger;
  return (
    <React.Fragment>
      <div>
        <Breadcrumb mainTitle="Administration" pageTitle="User"/>
        <Row>
          <Col lg={24} xl={24} xxl={24}>
            <Card style={{ marginBottom: customStyles.margin }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <div>
                    <img src={user.avatarUrl} alt="" style={{ height: "72px", width: "72px", borderRadius: "50%", marginRight: "30px" }}/>
                  </div>
                  <div>
                    <div>
                      <h5 style={{ fontWeight: "bold", fontSize: customStyles.h5, marginBottom: "6px" }}>{user.name}</h5>
                      <Text type="secondary" style={{ marginBottom: "0" }}>{user.email}</Text>
                    </div>
                    <Text type="secondary">Username: #{user.login}</Text>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <div>
                      <h6 style={{ fontSize: customStyles.h6, marginBottom: "6px" }}>Server Admin</h6>
                    </div>
                    <Popconfirm title="Change Permission" description="Are you sure to change server admin?" onConfirm={() => dispatch(updateUserPermission({id: id, isSuperAdmin: !user.isSuperAdmin }))}  okText="Change" cancelText="Cancel">
                      {user.isSuperAdmin ? <SafetyOutlined style={{ fontSize: '36px', color: color, cursor: 'pointer' }} />: <MinusOutlined style={{ fontSize: '36px', color: color, cursor: 'pointer' }} />}
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
      <ToastContainer />
      <Modal centered title="Change Password" open={changePasswordModalShow}  onCancel={() => setChangePasswordModalShow(!changePasswordModalShow)} footer={[]}>
        <Form  form={passwordForm} name="change-password" onFinish={updatePasswordSubmit}>       
          <div>
            <label style={{ marginBottom: "4px", display: "block" }}>Password</label>
              <Form.Item name="password" rules={[
                { required: true, message: "Password" }, 
                { pattern: new RegExp(`(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}`), message: "Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"}]}>
                  <Input.Password placeholder="Password" style={{ outline: "none", boxShadow: "none" }} />
              </Form.Item>
          </div>
          <div style={{ display:'flex', gap: '10px', justifyContent: "end" }}>
            <Button type="link" onClick={() => setChangePasswordModalShow(!changePasswordModalShow)} danger>Close</Button>
            <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }}>Update</Button>
          </div>
        </Form>
      </Modal>
      <Modal centered title="Edit Role" open={updateOrgRoleModalShow} onCancel={() => setOrgForRoleUpdate({})} footer={[]}>
        <Form  form={roleForm} name="edit-role" onFinish={handleOrgRoleSubmit}>       
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: customStyles.margin }}>
            <Form.Item name="role" rules={[{ required: true }]}>
              <Radio.Group name="role" options={roles} buttonStyle="solid" optionType="button"/>
            </Form.Item>
          </div>
          <div style={{ display:'flex', gap: '10px', justifyContent: "end" }}>
            <Button type="link" danger onClick={() => setOrgForRoleUpdate({})}>Close</Button>
            <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }}>
              Update
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal centered title="Add Organization" open={addOrgModalShow} onCancel={() => setAddOrgModalShow(!addOrgModalShow)} footer={[]}>
        <Form  form={orgForm} name="add-org" onFinish={handleAddOrgSubmit}>       
          <div>
            <label style={{ marginBottom: "4px", display: "block" }}>Organization</label>
            <Form.Item name="orgId" rules={[{ required: true }]}>
              <Select showSearch placeholder="Select organization" loading={getOrgsState.loading} onSearch={(q) => delayedGetOrgs(q)}  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} options={orgsOption}/>
            </Form.Item>
          </div>
          <div>
            <label style={{ marginBottom: "4px", display: "block" }}>Role</label>
            <Form.Item name="role" rules={[{ required: true }]}>
              <Radio.Group name="role" options={roles} buttonStyle="solid" optionType="button"/>
            </Form.Item>
          </div>
          <div style={{ display:'flex', gap: '10px', justifyContent: "end" }}>
            <Button type="link" danger onClick={() => setAddOrgModalShow(!addOrgModalShow)}>Close</Button>
            <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }}>
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(UserPage);
