import React from 'react'
import styled from 'styled-components';

import { Card, Row, Col, Image, Button } from 'antd';
import { HomeFilled } from '@ant-design/icons';
import error404 from "../../assets/images/error400.webp"

import ParticleAuth from '../../Common/ParticleAuth';
import { bootData } from '../../config';

const StyleWrapper = styled.div`
    background-color: ${({ theme }) => theme.token.authbgcolor};
`

const FourhundredfourPage = () => {
  document.title = "Error-" + bootData.settings.appTitle;

  return (
    <React.Fragment>
      <StyleWrapper className="auth-page-wrapper" >
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Col xs={24} xl={14}>
            <Card>
              <Row gutter={[16, 24]}>
                <ParticleAuth />
                <Col xs={24} lg={14} >
                  <Card style={{ border: "0px" }}>
                    <div className='text-center'>
                      <Image src={error404} alt='' style={{ width: "300px", height: "268px" }} />
                    </div>
                    <div className="text-center">
                      <div style={{position: "relative"}}>
                        <h4 style={{fontSize: "18px", textTransform: "uppercase", marginTop: "20px"}}>Opps, Not found</h4>
                        <div>
                          <Button href="/dashboard" type='primary'><HomeFilled />Back to home</Button>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </StyleWrapper>
    </React.Fragment>
  )
}
export default FourhundredfourPage