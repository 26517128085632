import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  org: {}
};

const currentOrgSlice = createSlice({
  name: "CurrentOrg",
  initialState,
  reducers: {
    orgSuccess(state, action) {
      state.org = action.payload
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
});

export const {
  orgSuccess,
  setLoading,
} = currentOrgSlice.actions

export default currentOrgSlice.reducer;