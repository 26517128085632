import { debounce } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from 'react-toastify';

import { Button, Card, Col, Input, Row, Popconfirm, Space, Table, Form, Modal, Radio } from 'antd';
import { Pen, Search, Trash2, CircleUserRound } from 'lucide-react';
import { StopOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import usecustomStyles from '../../Common/customStyles';
import Spinners from '../../Common/Spinner';
import Breadcrumb from '../../Common/Breadcrumb';
import { bootData } from "../../config";
import { roles } from "../../Common/data";

import { getServiceAccounts, removeServiceAccount, updateServiceAccount, createServiceAccount } from "../../slices/thunk";

const customStyles = usecustomStyles();

const ServiceAccountsPage = () => {
    document.title = "Service Accounts-" + bootData.settings.appTitle;

    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [createServiceAccountModalShow, setCreateServiceAccountModalShow] = useState(false)
    const toggleCreateServiceAccountModal = useCallback(() => (createServiceAccountModalShow)? setCreateServiceAccountModalShow(false): setCreateServiceAccountModalShow(true), [createServiceAccountModalShow]);

    const selectServiceAccounts = createSelector(
      (state) => state.ServiceAccounts,
      (serviceAccounts) => ({
        serviceAccounts: serviceAccounts.serviceAccounts,
        loading: serviceAccounts.loading,
        perPage: serviceAccounts.perPage,
        page: serviceAccounts.page,
        totalCount: serviceAccounts.totalCount,
      })
    );
    const { serviceAccounts, loading, totalCount, page, perPage } = useSelector(selectServiceAccounts);

    useEffect(() => {
      const query = {page: 1, perpage: 10, query: ''};
        dispatch(getServiceAccounts(query));
    }, [dispatch]);

    // Helper Functions
    const onPagination = (page, pageSize) => {
      const query = {page: page, perpage: pageSize, query: search};
      dispatch(getServiceAccounts(query));
    }
    const searchServiceAccounts = (e) => {
      setSearch(e.target.value);
      const query = {page: 1, perpage: 10, query: e.target.value};
      dispatch(getServiceAccounts(query));
    }
    const delayedSearchServiceAccounts = debounce((e) => searchServiceAccounts(e), 500);

    //Initialise Forms
    const [createServiceAccountForm] = Form.useForm();
    useEffect(() => {
      createServiceAccountForm.setFieldsValue({
        name: '',
        role: 'Viewer',
      });
    }, [createServiceAccountForm]);

    //Submit Forms
    const handleCreateServicedAccountSubmit = (values) => {
      dispatch(createServiceAccount(values));
      createServiceAccountForm.resetFields();
      toggleCreateServiceAccountModal();
    }

    //Table Columns
    const columns = [
      {
        align: 'center',
        render: (_, record) => (
          <img src={record.avatarUrl} alt='' height={36} style={{ borderRadius: "50%" }}></img>
        )
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'ID',
        dataIndex: 'login',
        sorter: (a, b) => a.login.localeCompare(b.login),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Role',
        dataIndex: 'role',
        sorter: (a, b) => a.role.localeCompare(b.role),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Disabled',
        align: 'center',
        render: (_, record) => {
          if (record.isDisabled === true) {
            return (<StopOutlined style={{ fontSize: '24px', color: customStyles.colorDanger, borderRadius: "50%"}}/>)
          }
          return (<></>);
        },
      },
      {
        title: 'Actions',
        align: 'center',
        render: (_, record) => (
          <Space>
            <Button href={`/org/serviceaccounts/${record.id}`} icon={<Pen size={12} />} size="small" />
            <Popconfirm title={(record.isDisabled === true)? "Enable service account": "Disable service account"} description={ `Are you sure to ${(record.isDisabled === true)? "Enable?": "Disable?"}`} onConfirm={() => {
              dispatch(updateServiceAccount(false, {...record, isDisabled: !record.isDisabled}));
            }}  okText={(record.isDisabled === true)? "Enable": "Disable"} cancelText="Cancel">
              <Button icon={(record.isDisabled === true)? <CheckOutlined size={12} />: <CloseOutlined size={12} />} size="small" danger={!(record.isDisabled === true)} />
            </Popconfirm>
            <Popconfirm title="Remove service account" description="Are you sure to remove?" onConfirm={() => dispatch(removeServiceAccount(record.id))}  okText="Remove" cancelText="Cancel">
              <Button icon={<Trash2 size={12} />} size="small" danger/>
            </Popconfirm>
          </Space>
        )
      },
    ];

    return (
        <React.Fragment>
            <Breadcrumb mainTitle="Administration" pageTitle="Service Accounts" />
            <Row gutter={[24]}>
              <Col lg={24} xl={24}>
                <Card style={{ marginBottom: customStyles.margin }} >
                  <div style={{ display: "flex", justifyContent: "space-between", gap: '10px' }}>
                    <div className="search-box" style={{ position: "relative", display: "flex", width: '100%' }}>
                      <Input type="text" allowClear placeholder="Search for name" style={{ paddingLeft: "40px" }}
                        onChange={(e) => delayedSearchServiceAccounts(e)}
                      />
                      <Search style={{ position: "absolute", left: "10px", top: "10px" }} size={14} />
                    </div>
                    <div>
                      <Button type='primary' onClick={toggleCreateServiceAccountModal} style={{ backgroundColor: customStyles.colorPrimary, boxShadow:'none' }}>
                        <CircleUserRound size={16}/>Create Service Account
                      </Button>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            {
              loading ? <Spinners/>
              : 
              <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}>
                <Table columns={columns} dataSource={(serviceAccounts || []).map((serviceAccount, index) => ({
                    ...serviceAccount,
                    key: index,
                  }))}
                  pagination={{
                    pageSize: perPage,
                    current: page,
                    defaultCurrent: 1,
                    total: totalCount,
                    onChange: onPagination,
                    hideOnSinglePage: true,
                  }}
                />
              </div>
            }
            <Modal centered title="Add User" open={createServiceAccountModalShow} onCancel={toggleCreateServiceAccountModal} footer={[]}>
              <Form  form={createServiceAccountForm} name="add-user" onFinish={handleCreateServicedAccountSubmit}>
                <div>
                  <label style={{ display: 'block', marginBottom: '4px' }}>Name</label>
                  <Form.Item name="name" rules={[{ required: true, message: 'Name is required' }]}>
                    <Input name="name" type="text" placeholder="Enter name" style={{boxShadow: 'none'}}/>
                  </Form.Item>
                </div>
                <div>
                  <label style={{ display: 'block', marginBottom: '4px' }}>Role</label>
                  <Form.Item name="role" initialValue="Viewer" rules={[{ required: true, message: 'Role is required' }]}>
                    <Radio.Group name="role" defaultValue="Viewer" options={roles} buttonStyle="solid" optionType="button"/>
                  </Form.Item>
                </div>
                <div style={{ display:'flex', gap: '10px', justifyContent: "end", marginTop: customStyles.margin }}>
                  <Form.Item>
                    <Button type="link" danger onClick={() => toggleCreateServiceAccountModal()}>Close</Button>
                    <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }}>Create</Button>
                  </Form.Item>
                </div>
              </Form>
            </Modal>
            <ToastContainer />
        </React.Fragment>
    )
}

export default ServiceAccountsPage