import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  success: false,
};

const verifyEmailSlice = createSlice({
  name: "VerifyEmail",
  initialState,
  reducers: {
    verifyEmailSuccess(state) {
      state.success = true
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  }
});

export const {
  verifyEmailSuccess,
  setLoading,
} = verifyEmailSlice.actions;

export default verifyEmailSlice.reducer;