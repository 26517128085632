import React from 'react';

//Breadcrumb
import Breadcrumb from '../../Common/Breadcrumb';
import { bootData } from '../../config';


const Dashboard = () => {
  document.title = "Dashboard-" + bootData.settings.appTitle;

  return (
    <>
      <Breadcrumb mainTitle={bootData.settings.appTitle} pageTitle="Dashboard" />
    </>
  )
}

export default Dashboard;
