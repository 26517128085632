import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ToastContainer } from 'react-toastify';


import { Button, Card, Col, Form, Input, Row } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';

import ParticleAuth from '../../Common/ParticleAuth';
import withRouter from '../../Common/withRouter';
import usecustomStyles from '../../Common/customStyles';
import { bootData } from '../../config';

import { signupUser, socialLogin } from "../../slices/thunk";

const customStyles = usecustomStyles();
const StyleLine = styled.div`
.signin-other-title {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        border-top: 1px dashed #eff2f7;
        top: 10px;
    }
    .title {
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: #d0d0d0;
        padding: 2px 16px;
    }
  }
`;
const StyleWrapper = styled.div`
    background-color: ${({ theme }) => theme.token.authbgcolor};
`

const SignupPage = (props) => {
  document.title = "Signup-" + bootData.settings.appTitle;

  const dispatch = useDispatch();
  const searchParams = useMemo(() => new URLSearchParams(props.router.location.search), [props.router.location.search]);

  const selectSignup = createSelector(
    (state) => state.Signup,
    (signup) => ({
        success: signup.success,
        loading: signup.loading,
    })
  );
  const { loading, success } = useSelector(selectSignup);

  //Initialise timer functions to redirect
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    if (success) {
      setTimeout(() => props.router.navigate("/login"), 3000);
      setTimer(3)
    }
  }, [dispatch, success, props.router]);
  useEffect(() => {
      if (timer) {
          setInterval(() => setTimer(timer - 1), 1000);
      }
  }, [timer])

  //Initialise Forms
  const [signupForm] = Form.useForm();
  useEffect(() => {
    signupForm.setFieldsValue({
      orgName: '',
      name: '',
      username: '',
      email: searchParams.get("email") || '',
      password: '',
      code: searchParams.get("code") || '',
    });
  }, [signupForm, searchParams]);

  //Submit Forms
  const handleSignupSubmit = (values) => {
    dispatch(signupUser(values));
    signupForm.resetFields();
  }

  const signIn = (type) => {
    dispatch(socialLogin(type));
  };


  return (
    <StyleWrapper className="auth-page-wrapper">
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xs={24} lg={14}>
          <Card>
            <Row gutter={[16, 24]}>
              <ParticleAuth />
              <Col xs={24} lg={14} >
                <Card style={{ border: "0px" }}>
                  <div className="text-center" style={{ margin: "20px" }}>
                    <h5 style={{ fontSize: "20px", color: customStyles.colorPrimary }}>Create New Account</h5>
                  </div>
                  <div style={{ marginTop: "30px", padding: "40px" }} >
                    <Form form={signupForm} name="signup" onFinish={handleSignupSubmit} size={"small"}>
                      <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Account Name</label>
                        <Form.Item name="orgName" style={{ marginBottom: "12px" }} rules={[{ required: true, message: "Account name is required" }]}>
                          <Input name="orgName" type="text" placeholder="Enter account name" style={{ boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Name</label>
                        <Form.Item name="name" style={{ marginBottom: "12px" }} rules={[{ required: true, message: "Name is required" }]}>
                          <Input name="name" type="text" placeholder="Enter name" style={{ boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Email</label>
                        <Form.Item name="email" style={{ marginBottom: "12px" }} rules={[
                          { required: true, message: "Email is required" },
                          { pattern: new RegExp(`^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$`), message: "Email is not valid" },
                        ]}>
                          <Input name="email" type="email" placeholder="Enter email" style={{ boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      {bootData.settings.verifyEmailEnabled && (
                        <div>
                          <label style={{ display: "block", marginBottom: "4px" }}>Code</label>
                          <Form.Item name="code" style={{ marginBottom: "12px" }} rules={[{ required: true, message: "Code is required" }]}>
                            <Input name="code" type="text" placeholder="Enter code" style={{ boxShadow: "none" }}/>
                          </Form.Item>
                        </div>
                      )}
                      <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Username</label>
                        <Form.Item name="username" style={{ marginBottom: "12px" }} rules={[{ required: true, message: "Username is required" }]}>
                          <Input name="username" type="text" placeholder="Enter username" style={{ boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Password</label>
                        <Form.Item name="password" style={{ marginBottom: "12px" }} rules={[{ required: true, message: "Password is required" }]}>
                          <Input.Password name="password" placeholder="Enter password" style={{ boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <p style={{ fontSize: "12px", fontStyle: "italic", marginBottom: "10px" }}>By registering you agree to the Lizant <Link href="/#" style={{ textDecoration: "underline", fontStyle: "normal" }}>Terms of Use</Link></p>
                      </div>
                      <div>
                        <Form.Item>
                          <Button type='primary' htmlType="submit" loading={loading} style={{ width: "100%" }}>Send verification email</Button>
                        </Form.Item>
                      </div>
                      <div style={{ paddingTop: "18px" }} className='text-center'>
                        <StyleLine className="signin-other-title"><h5 style={{ fontSize: "13px" }}>Create account with</h5></StyleLine>
                          <div style={{ paddingTop: "25px" }}>
                            <Button style={{ height: "50px", width: "50px", backgroundColor: customStyles.colorDangerBg, marginRight: "8px", color: customStyles.colorDanger, border: "0px" }}
                              onClick={e => { e.preventDefault(); signIn("google"); }} ><GoogleOutlined /></Button>
                          </div>
                      </div>
                    </Form>
                    <div style={{ marginTop: "50px" }} className="text-center">
                        <p>Already have an account ?  <a href="/login" style={{ marginRight: "5px", textDecoration: "underline", fontWeight: "bold" }}>Sign In</a></p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </StyleWrapper>
  )
}

export default withRouter(SignupPage);