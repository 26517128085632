import { postFakeLogin, getFakeLogout } from "../../../helpers/fakebackend_helper";
import { setLoading, logoutSuccess } from './reducer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const login = (values) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = postFakeLogin(values);
    var data = await response;
    if (data) {
      dispatch(setLoading(false));
      window.location.assign(data.redirectUrl);
    }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to login: " + error.message, { autoClose: 3000 });
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    sessionStorage.removeItem("authUser");
    const response = getFakeLogout();
    await response;
    dispatch(logoutSuccess(true));
    window.location.assign('/login');
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to logout: " + error.message, { autoClose: 3000 });
  }
};

export const socialLogin = (type) => async () => {
  try {
    window.location.assign(`/login/${type}`);
  } catch (error) {
    toast.error("Failed to login: " + error.message, { autoClose: 3000 });
  }
};