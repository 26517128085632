import { APIClient } from "./api_helper";
import * as url from "./url_helper"

const api = new APIClient();

export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

//Profile/Current User
export const updateFakeCurrentUser = (data) => api.put(url.USER, data);
export const getFakeCurrentUser = () => api.get(url.USER, null);
export const updateFakeCurrentUserPassword = (data) => api.put(`${url.USER}/password`, data);
export const getFakeProfileOrgs = () => api.get(`${url.USER}/orgs`, null);
export const getFakeLoggedinUserSessions = () => api.get(`${url.USER}/auth-tokens`, null);
export const revokeFakeLoggedinUserSession = (data) => api.create(`${url.USER}/revoke-auth-token`, data);

//CurrentOrg
export const getFakeCurrentOrgUsers = (query) => api.get(`${url.CURRENT_ORG_USERS}/search`, query);
export const updateFakeCurrentOrgUser = (data) => api.update(`${url.CURRENT_ORG_USERS}/${data.id}`, data);
export const removeFakeCurrentOrgUser = (id) => api.delete(`${url.CURRENT_ORG_USERS}/${id}`, null);
export const postFakeUserInvite = (query) => api.create(url.CURRENT_ORG_INVITES, query);
export const getFakeCurrentOrg = () => api.get(url.CURRENT_ORG, null);
export const updateFakeCurrentOrg = (data) => api.put(url.CURRENT_ORG, data);
export const updateFakeCurrentOrgAddress = (data) => api.put(`${url.CURRENT_ORG}/address`, data);

//Auth-Signup
export const postFakeSignup = (data) => api.create(url.POST_FAKE_SIGNUP, data);
export const postFakeInvitedSignup = (data) => api.create(`${url.USER_INVITE}/complete`, data);
export const getInvitedUser = (code) => api.get(`${url.USER_INVITE}/${code}`, null);
export const postFakeVerifyEmail = (data) => api.create(url.POST_FAKE_VERIFY_EMAIL, data);

// Auth-LoginLogout
export const postFakeLogin = (data) => api.create(url.POST_FAKE_LOGIN, data);
export const getFakeLogout = () => api.get(url.GET_FAKE_LOGOUT, null);
export const postFakeForgotPassword = (data) => api.create(url.POST_FAKE_PASSWORD_FORGET, data);
export const postFakeResetPassword = (data) => api.create(url.POST_FAKE_PASSWORD_RESET, data);

// Users
export const getFakeUsers = (query) => api.get(`${url.FAKE_USERS}/search`, query);
export const getFakeUser = (id) => api.get(`${url.FAKE_USERS}/${id}`, {accesscontrol: false});
export const getFakeUserOrgs = (id) => api.get(`${url.FAKE_USERS}/${id}/orgs`, null);
export const updateFakeUser = (data) => api.put(`${url.FAKE_USERS}/${data.id}`, data);

//Admin-User
export const removeFakeUser = (id) => api.delete(`${url.ADMIN_USERS}/${id}`, null);
export const disableFakeUser = (id) => api.create(`${url.ADMIN_USERS}/${id}/disable`, null);
export const enableFakeUser = (id) => api.create(`${url.ADMIN_USERS}/${id}/enable`, null);
export const getFakeUserAuthTokens = (id) => api.get(`${url.ADMIN_USERS}/${id}/auth-tokens`, null);
export const revokeFakeUserSession = (data) => api.create(`${url.ADMIN_USERS}/${data.id}/revoke-auth-token`, { authTokenId: data.tokenId });
export const revokeFakeUserAllSessions = (id) => api.create(`${url.ADMIN_USERS}/${id}/logout`, null);
export const updateFakeUserPassword = (data) => api.put(`${url.ADMIN_USERS}/${data.id}/password`, data);
export const updateFakeUserPermission = (data) => api.put(`${url.ADMIN_USERS}/${data.id}/permissions`, data);


//Admin-Invites
export const getFakeOrgInvites = () => api.get(url.CURRENT_ORG_INVITES, null);
export const revokeOrgInvite = (code) => api.update(`${url.CURRENT_ORG_INVITES}/${code}/revoke`, code);

//Admin-Orgs
export const getFakeOrgs = (query) => api.get(`${url.ORGS}`, query);
export const createFakeOrg = (data) => api.create(`${url.ORGS}`, data);
export const removeFakeOrg = (id) => api.delete(`${url.ORGS}/${id}`, null);
export const getFakeOrgUsers = (id, query) => api.get(`${url.ORGS}/${id}/users/search`, query);
export const updateFakeUserOrgRole = (data) => api.update(`${url.ORGS}/${data.orgId}/users/${data.userId}`, data);
export const removeFakeUserFromOrg = (data) => api.delete(`${url.ORGS}/${data.orgId}/users/${data.userId}`, null);
export const addFakeOrgUser = (data) => api.create(`${url.ORGS}/${data.orgId}/users`, data);

//Admin-Org
export const getFakeOrg = (id) => api.get(`${url.ORGS}/${id}`, null);
export const updateFakeOrg = (data) => api.put(`${url.ORGS}/${data.id}`, data);

//Admin-Settings
export const getFakeSettings = () => api.get(`${url.SETTINGS}`, null);
export const getFakeStats = () => api.get(`${url.STATS}`, null);

//Org-ServiceAccounts
export const getFakeServiceAccounts = (query) => api.get(`${url.SERVICE_ACCOUNTS}/search`, query);
export const createFakeServiceAccount = (data) => api.create(`${url.SERVICE_ACCOUNTS}`, data);
export const removeFakeServiceAccount = (id) => api.delete(`${url.SERVICE_ACCOUNTS}/${id}`, null);
export const updateFakeServiceAccount = (data) => api.update(`${url.SERVICE_ACCOUNTS}/${data.id}`, data);
export const getFakeServiceAccount = (id) => api.get(`${url.SERVICE_ACCOUNTS}/${id}`, null);
export const getFakeServiceAccountTokens = (id) => api.get(`${url.SERVICE_ACCOUNTS}/${id}/tokens`, null);
export const createFakeServiceAccountToken = (data) => api.create(`${url.SERVICE_ACCOUNTS}/${data.id}/tokens`, data);
export const revokeFakeServiceAccountToken = (data) => api.delete(`${url.SERVICE_ACCOUNTS}/${data.id}/tokens/${data.tokenId}`, null);