import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { Button, Card, Col, Form, Input, Row } from 'antd';

import usecustomStyles from '../../Common/customStyles';
import ParticleAuth from '../../Common/ParticleAuth';
import withRouter from '../../Common/withRouter';
import { bootData } from '../../config';

import { resetPassword } from "../../slices/thunk";

const customStyles = usecustomStyles();
const StyleWrapper = styled.div`
  background-color: ${({ theme }) => theme.token.authbgcolor};
`
const ResetPasswordPage = (props) => {
  document.title = "Reset Password-" + bootData.settings.appTitle;

  const dispatch = useDispatch();
  const searchParams = useMemo(() => new URLSearchParams(props.router.location.search), [props.router.location.search]);
  const code = searchParams.get("code");

  const selectForgotPassword = createSelector(
    (state) => state.ForgotPassword,
    (forgetPassword) => ({
      loading: forgetPassword.loading,
      success: forgetPassword.success,
    })
  );
  const { loading, success } = useSelector(selectForgotPassword);
  
  //Initialise timer functions to redirect
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    if (success) {
        setTimeout(() => props.router.navigate("/login"), 1000);
        setTimer(3)
    }
  }, [dispatch, success, props.router]);
  useEffect(() => {
    if (timer) {
      setInterval(() => setTimer(timer - 1), 1000);
    }
  }, [timer])

  //Initialise Forms
  const [resetPasswordForm] = Form.useForm();
  useEffect(() => {
    resetPasswordForm.setFieldsValue({
      newPassword: "",
      confirmPassword: ""
    });
  }, [resetPasswordForm]);

  //Validate Passwords
  const validPassword = Form.useWatch((values) => (values.newPassword === values.confirmPassword ? true : false), resetPasswordForm);
  const validateConfirmPassword = () => {
    if (validPassword) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("The two passwords that you entered do not match!")
    );
  };

  //Submit Forms
  const handleResetPasswordSubmit = (values) => {
    dispatch(resetPassword({...values, code: code}));
    resetPasswordForm.resetFields();
  };

  return (
    <StyleWrapper className="auth-page-wrapper">
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xs={24} lg={14}>
          <Card>
            <Row gutter={[16, 24]}>
              <ParticleAuth />
              <Col xs={24} lg={14} >
                <Card style={{ border: "0px" }}>
                  <div className="text-center" style={{ margin: "20px" }}>
                    <h5 style={{ fontSize: "20px", color: customStyles.colorPrimary }}>Change Password</h5>
                  </div>
                  <div style={{ marginTop: "30px", padding: "40px" }} >
                    <Form form={resetPasswordForm} name="reset-password" onFinish={handleResetPasswordSubmit} >
                      <div>
                        <label style={{ marginBottom: "4px", display: "block" }}>Password</label>
                        <Form.Item name="newPassword" rules={[
                          { required: true, message: "Password is required" },
                          { pattern: new RegExp(`(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}`), message: "Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"},
                        ]}>
                          <Input.Password placeholder="Enter password" style={{ outline: "none", boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <label style={{ marginBottom: "4px", display: "block" }}>Confirm Password</label>
                        <Form.Item name="confirmPassword" rules={[{ validator: validateConfirmPassword }]}>
                          <Input.Password placeholder="Enter password" style={{ outline: "none", boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div className="text-center">
                        <Form.Item>
                          <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }} loading={loading} >Update</Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </StyleWrapper>
  )
}

export default withRouter(ResetPasswordPage);