import axios from "axios";
import { bootData } from "../config";

axios.defaults.baseURL = "";
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

class APIClient {
  constructor() {
    this.isRefreshing = false;
    this.failedQueue = [];

    axios.interceptors.response.use(
      response => response.data ? response.data : response,
      error => this.handleError(error)
    );
  }

  processQueue(error) {
    this.failedQueue.forEach(({ resolve, reject }) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
    this.failedQueue = [];
  }

  handleError(error) {
    const originalRequest = error.config;
    if (originalRequest.url !== "/login" && error.response && error.response.status === 401) {
      if (!this.isRefreshing) {
        this.isRefreshing = true;

        return this.refreshSession()
          .then(() => {
            this.isRefreshing = false;
            this.processQueue(null);
            return axios(originalRequest);
          })
          .catch(err => {
            this.processQueue(err);
            return Promise.reject(err);
          });
      }

      return new Promise((resolve, reject) => {
        this.failedQueue.push({ resolve, reject });
      }).then(() => axios(originalRequest)).catch(err => Promise.reject(err));
    }
    const message = error.response.data || error.message || error;
    return Promise.reject(message);
  }

  refreshSession() {
    return fetch('/api/user/auth-tokens/rotate', { method: 'POST', credentials: 'include' })
      .then(response => {
        if (response.status === 200) {
          return Promise.resolve();
        }
        if (response.status === 401) {
          window.location.reload();
          return Promise.reject(new Error('Session expired, reloading.'));
        }
      })
      .catch(error => {
        console.error('Session refresh failed:', error);
        return Promise.reject(error);
      });
  }

  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  create = (url, data) => {
    return axios.post(url, data);
  };
  update = (url, data) => {
    return axios.patch(url, data);
  };
  put = (url, data) => {
    return axios.put(url, data);
  };
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  if (bootData.user.isSignedIn === true) {
    sessionStorage.setItem("authUser", JSON.stringify(bootData.user));
    return bootData.user;
  }
  sessionStorage.removeItem("authUser");
  return null;
};

export { APIClient, getLoggedinUser };