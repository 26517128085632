import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  user: {},
  orgs: [],
  profileAuthTokens: [],
};

const profileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    profileSuccess(state, action) {
      state.user = action.payload
      state.loading = false
    },
    profileOrgsSuccess(state, action) {
      state.orgs = action.payload
      state.loading = false
    },
    profileAuthTokensSuccess(state, action) {
      state.profileAuthTokens = action.payload
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  },
});

export const {
  profileSuccess,
  profileOrgsSuccess,
  profileAuthTokensSuccess,
  setLoading
} = profileSlice.actions

export default profileSlice.reducer;