import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  isLoggedOut: false,
};

const loginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    logoutSuccess(state) {
      state.isLoggedOut = true
    }
  },
});

export const {
  setLoading,
  logoutSuccess,
} = loginSlice.actions

export default loginSlice.reducer;