import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from 'styled-components';

import { Menu } from "antd";

import { ChevronLeft, ChevronRight, Gauge, UserSearch, Users, Building2, Building, Cog, TrendingUp, CircleUserRound } from "lucide-react";

import withRouter from "../Common/withRouter.js";
import { bootData, themecolor } from "../config.js";
import { StyleSimpleBar, StyledCollapsedButton, StyleBrandLogo, StyleSider } from "../Common/SidebarStyle.js";

const TextMuted = styled.div`
  color: ${({ theme }) => theme.token.textMute};
`;

const SidebarLayout = () => {
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [
    getItem("Menu", "Menu", null,
      [getItem(<Link to="/dashboard">Dashboard</Link>, "dashboard", <Gauge size={16} />)],
      "group"
    ),
    getItem("Organization", "Organization", null,
      [
        getItem(<Link to="/org/profile">Profile</Link>, "org-profile", <Building size={16}/>),
        getItem(<Link to="/org/users">Members</Link>, "org-users", <Users size={16}/>),
        getItem(<Link to="/org/serviceaccounts">Service Accounts</Link>, "org-serviceaccounts", <CircleUserRound size={16}/>),
      ],
      "group"
    ),
    getItem("Administration", "Administration", null,
      [
        getItem(<Link to="/admin/users">Users</Link>, "admin-users", <UserSearch size={16}/>),
        getItem(<Link to="/admin/orgs">Orgs</Link>, "admin-orgs", <Building2 size={16}/>),
        getItem(<Link to="/admin/settings">Settings</Link>, "admin-settings", <Cog size={16}/>),
        getItem(<Link to="/admin/stats">Stats</Link>, "admin-stats", <TrendingUp size={16}/>),
      ],
      "group"
    ),
  ];

  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const antHeaderMain = document.getElementById("antHeaderMain");
    if (antHeaderMain) {
      antHeaderMain.style.left = collapsed ? "100px" : "260px";
    }
    const antLayoutContent = document.getElementById("antLayoutContent");
    if (antLayoutContent) {
      antLayoutContent.style.marginLeft = collapsed ? "100px" : "260px";
    }
    const antFooterLayout = document.getElementById("antFooterLayout");
    if (antFooterLayout) {
      antFooterLayout.style.marginLeft = collapsed ? "100px" : "260px";
    }
  }, [collapsed]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    const antHeaderMain = document.getElementById("antHeaderMain");
    if (antHeaderMain) {
      antHeaderMain.style.left = !collapsed ? "100px" : "260px";
    }
    const antLayoutContent = document.getElementById("antLayoutContent");
    if (antLayoutContent) {
      antLayoutContent.style.marginLeft = !collapsed ? "100px" : "260px";
    }
    const antFooterLayout = document.getElementById("antFooterLayout");
    if (antFooterLayout) {
      antFooterLayout.style.marginLeft = !collapsed ? "100px" : "260px";
    }
  };

  const location = useLocation();
  const [activatedItem, setActivatedItem] = useState(() => {
    const currentPath = location.pathname.replace("/", "");
    return currentPath || "dashboard";
  });
  
  const toggleActivation = (key) => {
    setActivatedItem((prevActivatedItem) => (prevActivatedItem === key ? null : key));
  };
  
  
  return (
    <React.Fragment>
      <StyleSider
        id="sidebar-layout"
        width={themecolor.components.Menu.verticalSidebarWidth}
        collapsed={collapsed}
        collapsedWidth="100"
        breakpoint="lg"
      >
        <StyleBrandLogo className="demo-logo ant-mx-auto">
          <TextMuted style={{ lineHeight: "24px" }} className="brand-dark-logo ant-mx-auto"><h3 style={{ fontWeight: "bold" }}>{bootData.user.orgName}</h3></TextMuted>
          <img alt="Brand sm logo" src={bootData.appleTouchIcon} height={24} style={{ lineHeight: "24px" }} className="brand-sm-logo ant-mx-auto" />
          <StyledCollapsedButton themecolor={themecolor} type="link" onClick={toggleCollapsed}>
            {collapsed ? <ChevronRight /> : <ChevronLeft />}
          </StyledCollapsedButton>
        </StyleBrandLogo>
        <div>
          <StyleSimpleBar>
            <Menu
              selectedKeys={[activatedItem]}
              mode="inline"
              theme="light"
              items={items}
              collapsedWidth="100"
              onClick={({ key }) => toggleActivation(key)}
            ></Menu>
          </StyleSimpleBar>
        </div>
      </StyleSider>
    </React.Fragment>
  );
};

export default withRouter(SidebarLayout);
