import React, { useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from 'react-toastify';

import { Card, Col, Row, Table, Typography } from 'antd';

import Breadcrumb from '../../Common/Breadcrumb';
import usecustomStyles from '../../Common/customStyles';
import { bootData } from "../../config";
import Spinners from '../../Common/Spinner';

import { getStats } from "../../slices/thunk";

const customStyles = usecustomStyles();
const { Text } = Typography;

const StatsPage = () => {
  document.title = "Stats-" + bootData.settings.appTitle;

  const dispatch = useDispatch();

  const selectStats = createSelector(
    (state) => state.SettingsStats,
    (settingsstats) => ({
      stats: settingsstats.stats,
      loading: settingsstats.loading,
    })
  );
  const { stats, loading } = useSelector(selectStats);
  const chunkArray = (arr, size) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += size) {
      chunks.push(arr.slice(i, i + size));
    }
    return chunks;
  };
  const chunkedRows = chunkArray(Object.entries(stats), 9);

  useEffect(() => {
    dispatch(getStats());
  }, [dispatch]);

  //Table Columns
  const columns = [
    {
      title: 'Parameter',
      render: (_, record) => {
        if (record.length > 0) {
          return (<Text type="secondary" >{record[0]}</Text>);
        }
        return (<></>);
      },
    },
    {
      title: 'Value',
      align: 'center',
      render: (_, record) => {
        if (record.length > 1) {
          return (<Text type="secondary" >{record[1]}</Text>);
        }
        return (<></>);
      },
    }
  ];

  return (
    <React.Fragment>
      <Breadcrumb mainTitle="Administration" pageTitle="Stats" />
      <Row gutter={[24]}>
        <Col lg={24} xl={24}>
          <Card style={{ marginBottom: customStyles.margin }}>
            <div style={{ display: "flex", alignItems: "center" }}> 
              <Text type="primary" >Stats of the application.</Text>
            </div>
          </Card>
        </Col>
      </Row>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <Row className="d-flex" style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {
            loading ? <Spinners/>
          :
            chunkedRows.map((chunk, columnIndex) => (
              <Col key={columnIndex}>
                <Card style={{ marginBottom: customStyles.margin, marginRight: customStyles.margin, height: '100%' }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Table columns={columns} dataSource={chunk} 
                      pagination={{
                        hideOnSinglePage: true,
                        defaultCurrent: 1,
                        pageSize: chunk.length,
                        total: chunk.length,
                      }}
                    />
                  </div>
                </Card>
              </Col>
            ))
          }
        </Row>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}

export default StatsPage;