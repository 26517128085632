import { serviceAccountsSuccess, serviceAccountSuccess, serviceAccountTokensSuccess, setLoading, createServiceAccountTokenInitiated, serviceAccountTokenSuccess } from "./reducer"
import { getFakeServiceAccounts, createFakeServiceAccount, removeFakeServiceAccount, updateFakeServiceAccount, getFakeServiceAccount, getFakeServiceAccountTokens, createFakeServiceAccountToken, revokeFakeServiceAccountToken } from "../../../helpers/fakebackend_helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getServiceAccounts = (query) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = getFakeServiceAccounts(query);
      const data = await response;
      if (data) {
          dispatch(serviceAccountsSuccess(data));
      }
  } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to get users: " + error.message, { autoClose: 3000 });
  }
}

export const removeServiceAccount = (id) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = removeFakeServiceAccount(id);
    const data = await response;
    const query = {page: 1, perpage: 10, query: '', accesscontrol: false};
    dispatch(getServiceAccounts(query));
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to remove service account: " + error.message, { autoClose: 3000 });
  }
}

export const createServiceAccount = (data) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = createFakeServiceAccount(data);
    const res = await response;
    const query = {page: 1, perpage: 10, query: '', accesscontrol: false};
    dispatch(getServiceAccounts(query));
    toast.success(res.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to create service account: " + error.message, { autoClose: 3000 });
  }
}

export const updateServiceAccount = (isServiceAccountPage, data) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = updateFakeServiceAccount(data);
    const res = await response;
    if (isServiceAccountPage) {
      dispatch(getServiceAccount(data.id));
    } else {
      const query = {page: 1, perpage: 10, query: '', accesscontrol: false};
      dispatch(getServiceAccounts(query));
    }
    toast.success(res.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update service account: " + error.message, { autoClose: 3000 });
  }
}

export const getServiceAccount = (id) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = getFakeServiceAccount(id);
    const data = await response;
    if (data) {
        dispatch(serviceAccountSuccess(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get service account: " + error.message, { autoClose: 3000 });
  }
}

export const getServiceAccountTokens = (id) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = getFakeServiceAccountTokens(id);
    const data = await response;
    if (data) {
        dispatch(serviceAccountTokensSuccess(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get service account tokens: " + error.message, { autoClose: 3000 });
  }
}

export const createServiceAccountToken = (data) => async (dispatch) => {
  try {
    let response;
    dispatch(createServiceAccountTokenInitiated());
    dispatch(setLoading(true));
    response = createFakeServiceAccountToken(data);
    const res = await response;
    dispatch(serviceAccountTokenSuccess(res));
    dispatch(getServiceAccountTokens(data.id));
    toast.success(res.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to create service account token: " + error.message, { autoClose: 3000 });
  }
}

export const revokeServiceAccountToken = (data) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = revokeFakeServiceAccountToken(data);
    const res = await response;
    dispatch(getServiceAccountTokens(data.id));
    toast.success(res.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to revoke service account token: " + error.message, { autoClose: 3000 });
  }
}