import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: {},
  userOrgs: [],
  userAuthTokens: [],
  loading: true,
};

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    userSuccess(state, action) {
      state.user = action.payload
      state.loading = false
    },
    userOrgsSuccess(state, action) {
      state.userOrgs = action.payload
      state.loading = false
    },
    userAuthTokensSuccess(state, action) {
      state.userAuthTokens = action.payload
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
});

export const {
  userSuccess,
  userOrgsSuccess,
  userAuthTokensSuccess,
  setLoading,
} = userSlice.actions

export default userSlice.reducer;