import { debounce } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from 'react-toastify';

import { Button, Card, Col, Input, Row, Popconfirm, Space, Table, Form, Modal, Tabs, Radio } from 'antd';
import { Search, Trash2 } from 'lucide-react';
import { StopOutlined } from '@ant-design/icons';

import Breadcrumb from '../../Common/Breadcrumb';
import usecustomStyles from '../../Common/customStyles';
import withRouter from "../../Common/withRouter";
import { bootData } from "../../config";
import Spinners from '../../Common/Spinner';
import { roles } from "../../Common/data";

import { getOrg, getOrgUsersSearch, removeOrgUser, updateOrgUserRole, updateOrg } from "../../slices/thunk";

const customStyles = usecustomStyles();

const OrgPage = (props) => {
  document.title = "Org-" + bootData.settings.appTitle;

  const [search, setSearch] = useState('');
  const dispatch = useDispatch();
  let { id } = props.router.params;
  const [orgUser, setOrgUser] = useState({});

  const selectOrg = createSelector(
    (state) => state.Org,
    (org) => ({
      org: org.org,
      users: org.users,
      loading: org.loading,
      totalCount: org.totalUsersCount,
      page: org.page,
      perPage: org.perPage,
    })
  );
  const { org, users, loading, totalCount, page, perPage } = useSelector(selectOrg);

  useEffect(() => {
    dispatch(getOrg(id));
    const query = {page: 1, perpage: 10, query: ''};
    dispatch(getOrgUsersSearch(id, query));
  }, [dispatch, id]);

  // Helper Functions
  const onPagination = (page, pageSize) => {
    const query = {page: page, perpage: pageSize, query: search};
    dispatch(getOrgUsersSearch(id, query));
  }
  const searchUsers = (e) => {
    setSearch(e.target.value);
    const query = {page: 1, perpage: 10, query: e.target.value};
    dispatch(getOrgUsersSearch(id, query));
  }
  const delayedUserSearch = debounce((e) => searchUsers(e), 500);

  //Modal
  const [updateOrgRoleModalShow, setUpdateOrgRoleModalShow] = useState(false)
  const setUserForRoleUpdate = useCallback((user) => { 
    setOrgUser(user);
    setUpdateOrgRoleModalShow(!updateOrgRoleModalShow);
  }, [updateOrgRoleModalShow]);

  //Initialise Forms
  const [updateOrgForm] = Form.useForm();
  const [updateUserRoleForm] = Form.useForm();
  useEffect(() => {
    updateOrgForm.setFieldsValue({
      name: org.name || "",
    });
  }, [updateOrgForm, org]);
  useEffect(() => {
    updateUserRoleForm.setFieldsValue({
      role: orgUser.role || 'Viewer',
    });
  }, [updateUserRoleForm, orgUser]);
    
  //Submit Forms
  const handleUpdateOrgSubmit = (values) => {
    dispatch(updateOrg({...values, id: id}));
    updateOrgForm.resetFields();
  }
  const handleOrgRoleSubmit = (values) => {
    dispatch(updateOrgUserRole({...values, orgId: id, userId: orgUser.userId}));
    setUserForRoleUpdate({});
    updateUserRoleForm.resetFields();
  }

  //Table Columns
  const columns = [
    {
      align: 'center',
      render: (_, record) => (
        <img src={record.avatarUrl} alt='' height={36} style={{ borderRadius: "50%" }}></img>
      )
    },
    {
      title: 'Login',
      dataIndex: 'login',
      sorter: (a, b) => a.login.localeCompare(b.login),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Role',
      dataIndex: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Last Seen',
      dataIndex: 'lastSeenAtAge',
    },
    {
      title: 'Disabled',
      align: 'center',
      render: (_, record) => {
        if (record.isDisabled === true) {
          return (<StopOutlined style={{ fontSize: '24px', color: customStyles.colorDanger, borderRadius: "50%"}}/>)
        }
        return (<></>);
      },
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Button type="link" onClick={() => setUserForRoleUpdate(record)} size="small">Change Role</Button>
          <Popconfirm title="Remove User" description="Are you sure to remove?" onConfirm={() => dispatch(removeOrgUser({orgId: record.orgId, userId: record.userId,}))}  okText="Remove" cancelText="Cancel">
            <Button icon={<Trash2 size={12} />} size="small" danger/>
          </Popconfirm>
        </Space>
      )
    },
  ];

  //Tab Items
  const items = [
    {
      key: "1",
      label: <span>Users</span>,
      children: (
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }} >
              <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: customStyles.margin }}>
                <div className="search-box" style={{ position: "relative", display: "flex", width: '100%' }}>
                  <Input type="text" allowClear placeholder="Search for name or designation..." style={{ paddingLeft: "40px" }}
                    onChange={(e) => delayedUserSearch(e)}
                  />
                  <Search style={{ position: "absolute", left: "10px", top: "10px" }} size={14} />
                </div>
              </div>
              {
                loading ? <Spinners/>
              : 
                <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}>
                  <Table columns={columns} dataSource={(users || []).map((user, index) => ({ ...user, key: index }))}
                    pagination={{ current: page, pageSize: perPage, defaultCurrent: 1, total: totalCount, onChange: onPagination, hideOnSinglePage: true }}
                  />
                </div>
              }
            </Card>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Breadcrumb mainTitle="Administration" pageTitle="Org" />
      <Row gutter={[24]}>
        <Col lg={24} xl={24}>
          <Card style={{ marginBottom: customStyles.margin }}>
            {
              loading ? <Spinners/>
            :
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form style={{ width: '100%'}} form={updateOrgForm} name="update-org" onFinish={handleUpdateOrgSubmit}>
                <div>
                  <Space.Compact style={{ width: '100%'}} >
                    <Form.Item style={{ display: 'block', marginBottom: "0", width: '100%'}} name="name" rules={[{ required: true, message: "Name is required" }]} >
                      <Input  name="name" type="text" placeholder="Enter name" style={{ boxShadow: "none" }}/>
                    </Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>Update</Button>
                  </Space.Compact>
                </div>
              </Form>
            </div>
            }
          </Card>
        </Col>
      </Row>
      <Tabs defaultActiveKey="1" items={items} />
      <ToastContainer />
      <Modal centered title="Edit Role" open={updateOrgRoleModalShow} onCancel={() => setUserForRoleUpdate({})} footer={[]}>
        <Form  form={updateUserRoleForm} name="edit-role" onFinish={handleOrgRoleSubmit}>       
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: customStyles.margin }}>
            <Form.Item name="role" rules={[{ required: true }]}>
              <Radio.Group name="role" options={roles} buttonStyle="solid" optionType="button"/>
            </Form.Item>
          </div>
          <div style={{ display:'flex', gap: '10px', justifyContent: "end" }}>
            <Button type="link" danger onClick={() => setUserForRoleUpdate({})}>Close</Button>
            <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }}>
              Update
            </Button>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(OrgPage);