import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  success: false,
};

const forgotPasswordSlice = createSlice({
  name: "ForgotPassword",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    resetPasswordSuccess(state) {
      state.success = true
      state.loading = false
    },
  },
});

export const {
  setLoading,
  resetPasswordSuccess,
} = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer;
