import { getFakeCurrentOrg, updateFakeCurrentOrgAddress, updateFakeCurrentOrg } from "../../../helpers/fakebackend_helper";
import { orgSuccess, setLoading } from "./reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getCurrentOrg = () => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = getFakeCurrentOrg();
    const data = await response;
    if (data) {
      dispatch(orgSuccess(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get org: " + error.message, { autoClose: 3000 });
  }
};

export const updateCurrentOrg = (values) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = updateFakeCurrentOrg(values);
    const data = await response;
    dispatch(getCurrentOrg());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update org: " + error.message, { autoClose: 3000 });
  }
};

export const updateCurrentOrgAddress = (values) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = updateFakeCurrentOrgAddress(values);
    const data = await response;
    dispatch(getCurrentOrg());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update address: " + error.message, { autoClose: 3000 });
  }
};