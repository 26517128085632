import { debounce } from 'lodash';
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from 'react-toastify';

import { Button, Card, Col, Input, Row, Popconfirm, Space, Table } from 'antd';
import { Pen, Search, Trash2 } from 'lucide-react';
import { SafetyOutlined, StopOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import usecustomStyles from '../../Common/customStyles';
import Spinners from '../../Common/Spinner';
import Breadcrumb from '../../Common/Breadcrumb';
import { bootData } from "../../config";

import { getUsers, removeUser, enableUser, disableUser } from "../../slices/thunk";

const customStyles = usecustomStyles();

const UsersPage = () => {
    document.title = "Users-" + bootData.settings.appTitle;

    const dispatch = useDispatch();
    const [search, setSearch] = useState('');

    const selectUsers = createSelector(
      (state) => state.Users,
      (users) => ({
        users: users.users,
        loading: users.loading,
        perPage: users.perPage,
        page: users.page,
        totalCount: users.totalCount,
      })
    );
    const { users, loading, totalCount, page, perPage } = useSelector(selectUsers);

    useEffect(() => {
      const query = {page: 1, perpage: 10, query: ''};
        dispatch(getUsers(query));
    }, [dispatch]);

    // Helper Functions
    const onPagination = (page, pageSize) => {
      const query = {page: page, perpage: pageSize, query: search};
      dispatch(getUsers(query));
    }
    const searchUsers = (e) => {
      setSearch(e.target.value);
      const query = {page: 1, perpage: 10, query: e.target.value};
      dispatch(getUsers(query));
    }
    const delayedSearchUser = debounce((e) => searchUsers(e), 500);

    //Table Columns
    const columns = [
      {
        align: 'center',
        render: (_, record) => (
          <img src={record.avatarUrl} alt='' height={36} style={{ borderRadius: "50%" }}></img>
        )
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Login',
        dataIndex: 'login',
        sorter: (a, b) => a.login.localeCompare(b.login),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Email',
        dataIndex: 'email',
        sorter: (a, b) => a.email.localeCompare(b.email),
        sortDirections: ['ascend', 'descend'],
      },
      {
        title: 'Last Seen',
        dataIndex: 'lastSeenAtAge',
        render: (text) => {
          if (text === '10 years') { 
            return(<span>Never</span>);
          }
          return(<span>{text}</span>);
        },
      },
      {
        title: 'Server Admin',
        align: 'center',
        render: (_, record) => {
          if (record.isAdmin === true) { 
            return(<SafetyOutlined style={{ fontSize: '24px', color: customStyles.colorInfo, borderRadius: "50%" }} />) 
          }
          return(<></>);
        },
      },
      {
        title: 'Disabled',
        align: 'center',
        render: (_, record) => {
          if (record.isDisabled === true) {
            return (<StopOutlined style={{ fontSize: '24px', color: customStyles.colorDanger, borderRadius: "50%"}}/>)
          }
          return (<></>);
        },
      },
      {
        title: 'Actions',
        align: 'center',
        render: (_, record) => (
          <Space>
            <Button href={`/admin/users/${record.id}`} icon={<Pen size={12} />} size="small" />
            <Popconfirm title={(record.isDisabled === true)? "Enable user": "Disable user"} description={ `Are you sure to ${(record.isDisabled === true)? "Enable?": "Disable?"}`} onConfirm={() => {
              if (record.isDisabled === true) {
              dispatch(enableUser(record.id));
              } else {
                dispatch(disableUser(record.id));
              }
            }}  okText={(record.isDisabled === true)? "Enable": "Disable"} cancelText="Cancel">
              <Button icon={(record.isDisabled === true)? <CheckOutlined size={12} />: <CloseOutlined size={12} />} size="small" danger={!(record.isDisabled === true)} />
            </Popconfirm>
            <Popconfirm title="Remove user" description="Are you sure to remove?" onConfirm={() => dispatch(removeUser(record.id))}  okText="Remove" cancelText="Cancel">
              <Button icon={<Trash2 size={12} />} size="small" danger/>
            </Popconfirm>
          </Space>
        )
      },
    ];

    return (
        <React.Fragment>
            <Breadcrumb mainTitle="Administration" pageTitle="Users" />
            <Row gutter={[24]}>
              <Col lg={24} xl={24}>
                <Card style={{ marginBottom: customStyles.margin }} >
                  <div style={{ display: "flex", justifyContent: "space-between", gap: '10px' }}>
                    <div className="search-box" style={{ position: "relative", display: "flex", width: '100%' }}>
                      <Input type="text" allowClear placeholder="Search for name or designation..." style={{ paddingLeft: "40px" }}
                        onChange={(e) => delayedSearchUser(e)}
                      />
                      <Search style={{ position: "absolute", left: "10px", top: "10px" }} size={14} />
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
            {
              loading ? <Spinners/>
              : 
              <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}>
                <Table columns={columns} dataSource={(users || []).map((user, index) => ({
                    ...user,
                    key: index,
                  }))}
                  pagination={{
                    pageSize: perPage,
                    current: page,
                    defaultCurrent: 1,
                    total: totalCount,
                    onChange: onPagination,
                    hideOnSinglePage: true
                  }}
                />
              </div>
            }
            <ToastContainer />
        </React.Fragment>
    )
}

export default UsersPage