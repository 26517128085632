import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  serviceAccounts: [],
  totalCount: 0,
  page: 1,
  perPage: 10,
  serviceAccount: {},
  tokens: [],
  token: null
};

const serviceAccountsSlice = createSlice({
  name: "ServiceAccounts",
  initialState,
  reducers: {
    serviceAccountsSuccess(state, action) {
      state.serviceAccounts = action.payload.serviceAccounts
      state.totalCount = action.payload.totalCount
      state.page = action.payload.page
      state.perPage = action.payload.perPage
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    serviceAccountSuccess(state, action) {
      state.serviceAccount = action.payload
      state.loading = false
    },
    serviceAccountTokensSuccess(state, action) {
      state.tokens = action.payload
      state.loading = false 
    },
    createServiceAccountTokenInitiated(state) {
      state.token = null
    },
    serviceAccountTokenSuccess(state, action) {
      state.token = action.payload
      state.loading = false
    }
  },
});

export const {
  serviceAccountsSuccess,
  serviceAccountSuccess,
  serviceAccountTokensSuccess,
  createServiceAccountTokenInitiated,
  serviceAccountTokenSuccess,
  setLoading,
} = serviceAccountsSlice.actions

export default serviceAccountsSlice.reducer;
