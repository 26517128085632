import { getOrgInvitesSuccess } from "./reducer"
import { getFakeOrgInvites, revokeOrgInvite } from "../../../helpers/fakebackend_helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getOrgInvites = () => async (dispatch) => {
  try {
      let response;
      response = getFakeOrgInvites();
      const data = await response;
      if (data) {
          dispatch(getOrgInvitesSuccess(data));
      }
  } catch (error) {
      toast.error("Failed to get invites", { autoClose: 3000 });
  }
}

export const revokeOrgInvites = (code) => async (dispatch) => {
  try {
      let response;
      response = revokeOrgInvite(code);
      const data = await response;
      if (data) {
          dispatch(getOrgInvites());
          toast.success("Invite revoked successfully", { autoClose: 3000 });
      }
  } catch (error) {
      toast.error("Failed to revoke invite", { autoClose: 3000 });
  }
}
