import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  users: [],
  totalCount: 0,
  page: 1,
  perPage: 10,
};

const usersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    usersSuccess(state, action) {
      state.users = action.payload.users
      state.totalCount = action.payload.totalCount
      state.page = action.payload.page
      state.perPage = action.payload.perPage
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
});

export const {
  usersSuccess,
  setLoading,
} = usersSlice.actions

export default usersSlice.reducer;