import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  orgs: [],
  totalOrgsCount: 0,
  page: 1,
  perPage: 10,
};

const orgsSlice = createSlice({
  name: "Orgs",
  initialState,
  reducers: {
    orgsSuccess(state, action) {
      state.orgs = action.payload.orgs
      state.totalOrgsCount = action.payload.totalCount
      state.page = action.payload.page
      state.perPage = action.payload.perPage
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
});

export const {
  orgsSuccess,
  setLoading,
} = orgsSlice.actions

export default orgsSlice.reducer;