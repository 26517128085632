import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";

import { Card, Col, Form, Input, Row, Button, Typography, Tabs, Select, Popconfirm, Table } from "antd";
import { ToastContainer } from 'react-toastify';
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';

import Breadcrumb from "../../Common/Breadcrumb";
import withRouter from "../../Common/withRouter";
import usecustomStyles from "../../Common/customStyles";
import { bootData } from "../../config";
import Spinners from '../../Common/Spinner';
import { formatDateTime } from "../../Common/data";

import { getProfile, updateProfile, changePassword, getProfileOrgs, revokeProfileSession, getProfileSessions } from "../../slices/thunk";

const customStyles = usecustomStyles();
const { Text } = Typography;

const ProfilePage = () => {
  document.title = "Profile-" + bootData.settings.appTitle;
  const dispatch = useDispatch();

  const selectProfile = createSelector(
    (state) => state.Profile,
    (profile) => ({
      user: profile.user,
      loading: profile.loading,
      orgs: profile.orgs,
      authTokens: profile.profileAuthTokens,
    })
  );
  const { user, loading, orgs, authTokens } = useSelector(selectProfile);
  const orgOptions = useMemo(() => orgs.map((org)=> {
    return {label: org.name, description: org.role, value: org.orgId};
  }), [orgs]);

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getProfileOrgs());
    dispatch(getProfileSessions());
  }, [dispatch]);

  //Table User Auth Tokens Columns
  const authTokensColumns = [
    {
      title: 'Last seen',
      align: 'center',
      dataIndex: 'seenAt',
      render: (text, record) => {
        if (record.isActive) {
          return (<span>Now</span>);
        }
        return (<span>{formatDateTime(text)}</span>);
      }
    },
    {
      title: 'Logged on',
      align: 'center',
      dataIndex: 'createdAt',
      render: (text) => (
        <span>{formatDateTime(text)}</span>
      )
    },
    {
      title: 'IP address',
      dataIndex: 'clientIp',
      sorter: (a, b) => a.clientIp.localeCompare(b.clientIp),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Browser and OS',
      render: (_, record) => (
        <span>{`${record.browser} on ${record.os} ${record.osVersion}`}</span>
      )
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_, record) => (
        <Popconfirm title="Logout" description="Are you sure to logout?" onConfirm={() => {dispatch(revokeProfileSession({authTokenId: record.id}))}}  okText="Logout" cancelText="Cancel">
          <Button icon={<LogoutOutlined size={12} />} size="small" danger/>
        </Popconfirm>
      )
    },
  ];

  //Initialise Forms
  const [passwordForm] = Form.useForm();
  const [profileForm] = Form.useForm();
  useEffect(() => {
    profileForm.setFieldsValue({
      name: user.name || "",
      email: user.email || "",
      username: user.login || "",
    });
  }, [profileForm, user]);
  useEffect(() => {
    passwordForm.setFieldsValue({
      oldpassword: "",
      newpassword: "",
      confirmpassword: "",
    });
  }, [passwordForm]);

  //Validate Passwords
  const validPassword = Form.useWatch(
    (values) => (values.newpassword === values.confirmpassword ? true : false),
    passwordForm
  );
  const validateConfirmPassword = () => {
    if (validPassword) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("The two passwords that you entered do not match!")
    );
  };

  //Submit Forms
  const updateProfileSubmit = (values) => {
    dispatch(updateProfile({ ...values, login: values.username }));
  };
  const handlePasswordSubmit = (values) => {
    dispatch(changePassword(values));
    passwordForm.resetFields();
  };

  //Helper Functions
  const onChange = (value) => {
    window.location.assign(`/profile/switch-org/${value}`);
  };

  //Tab Items
  const items = [
    {
      key: "1",
      label: <span>Update Profile</span>,
      children: (
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }}>
              <Form layout={'horizontal'} labelCol={{flex: '200px'}} wrapperCol={{flex: 1}} style={{ maxWidth: 'none' }} form={profileForm} name="update-profile" onFinish={updateProfileSubmit}>
                <div>
                  <Form.Item label="Name" name="name" rules={[{ required: true, message: "Name is required" }]}>
                    <Input name="name" type="text" placeholder="Enter name" style={{ boxShadow: "none" }}/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item label="User Name" name="username" rules={[{ required: true, message: "Username is required" }]} >
                    <Input name="username" type="text" placeholder="Enter username" style={{ boxShadow: "none" }} />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item label="Email" name="email" rules={[{ required: true, message: "Email is required" }]}>
                    <Input name="email" type="email" placeholder="Enter email" style={{ boxShadow: "none" }} />
                  </Form.Item>
                </div>
                <div className="text-center">
                  <Form.Item>
                    <Button loading={loading} htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }}>Update</Button>
                  </Form.Item>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: <span>Change Password</span>,
      children: (
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }}>
              <Form layout={'horizontal'} labelCol={{flex: '200px'}} wrapperCol={{flex: 1}} style={{ maxWidth: 'none' }}  form={passwordForm} name="change-password" onFinish={handlePasswordSubmit}>
                <div>
                  <Form.Item label="Old Password" name="oldpassword" rules={[ { required: true, message: "Old password is required"}]}>
                    <Input.Password placeholder="Enter old password" style={{ outline: "none", boxShadow: "none" }}/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item label="Password" name="newpassword" rules={[
                      { required: true, message: "Password is required" },
                      { pattern: new RegExp(`(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}`), message: "Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"},
                    ]}>
                    <Input.Password placeholder="Enter password" style={{ outline: "none", boxShadow: "none" }}/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item label="Confirm Password" name="confirmpassword" rules={[{ validator: validateConfirmPassword }]}>
                    <Input.Password placeholder="Confirm password" style={{ outline: "none", boxShadow: "none" }}/>
                  </Form.Item>
                </div>
                <div className="text-center">
                  <Form.Item>
                    <Button loading={loading} htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white"}}>Change Password</Button>
                  </Form.Item>
                </div>
              </Form>
            </Card>
          </Col>
        </Row>
      ),
    },
    {
      key: "3",
      label: <span>Sessions</span>,
      children: (
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }} >
              {
                loading ? <Spinners/>
              :
                <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}> 
                  <Table columns={authTokensColumns} dataSource={(authTokens || []).map((session, index) => ({ ...session, key: index }))}
                    pagination={{ pageSize: 10, total: authTokens.length, hideOnSinglePage: true }}
                  />
                </div>
              }
            </Card>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div>
        <Breadcrumb mainTitle={bootData.settings.appTitle} pageTitle="Profile"/>
        <Row>
          <Col lg={24} xl={24} xxl={24}>
            <Card style={{ marginBottom: customStyles.margin }}>
              <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", marginRight: "30px" }}>
                    <img src={user.avatarUrl} alt="" style={{  height: "72px", width: "72px", borderRadius: "50%",}}/>
                    <Button style={{ marginTop: "8px" }} href="https://gravatar.com" target="_blank"  shape="circle" icon={<EditOutlined />} size="small" />
                  </div>
                  <div>
                    <div>
                      <h5 style={{ fontWeight: "bold", fontSize: customStyles.h5, marginBottom: "6px" }}>{user.name}</h5>
                      <Text type="secondary" style={{ marginBottom: "0" }}>{user.email}</Text>
                    </div>
                    <Text type="secondary">Username: #{user.login}</Text>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                  <Select style={{ minWidth: "200px"}} onChange={onChange} placeholder="Select Organization" filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())} value={bootData.user.orgId}  options={orgOptions} />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default withRouter(ProfilePage);
