import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  success: false,
  loading: false,
  invite: {},
};

const signupSlice = createSlice({
  name: "Signup",
  initialState,
  reducers: {
    signupSuccessful(state) {
      state.success = true;
      state.loading = false;
    },
    inviteSuccess(state, action) {
      state.invite = action.payload;
      state.loading = false;
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  }
});

export const {
  signupSuccessful,
  inviteSuccess,
  setLoading
} = signupSlice.actions;

export default signupSlice.reducer;