export function formatDateTime(dateText) {
  const date = new Date(dateText);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
}