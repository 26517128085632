import { useProfile } from "./UserHooks";

const AccessControlAction = {
  UsersRead: 'users:read',
  UsersWrite: 'users:write',
  UsersAuthTokenList: 'users.authtoken:read',
  UsersAuthTokenUpdate: 'users.authtoken:write',
  UsersPasswordUpdate: 'users.password:write',
  UsersDelete: 'users:delete',
  UsersCreate: 'users:create',
  UsersEnable: 'users:enable',
  UsersDisable: 'users:disable',
  UsersPermissionsUpdate: 'users.permissions:write',
  UsersLogout: 'users:logout',
  UsersQuotasList: 'users.quotas:read',
  UsersQuotasUpdate: 'users.quotas:write',

  ServiceAccountsRead: 'serviceaccounts:read',
  ServiceAccountsCreate: 'serviceaccounts:create',
  ServiceAccountsWrite: 'serviceaccounts:write',
  ServiceAccountsDelete: 'serviceaccounts:delete',
  ServiceAccountsPermissionsRead: 'serviceaccounts.permissions:read',
  ServiceAccountsPermissionsWrite: 'serviceaccounts.permissions:write',

  OrgsRead: 'orgs:read',
  OrgsPreferencesRead: 'orgs.preferences:read',
  OrgsWrite: 'orgs:write',
  OrgsPreferencesWrite: 'orgs.preferences:write',
  OrgsCreate: 'orgs:create',
  OrgsDelete: 'orgs:delete',
  OrgUsersRead: 'org.users:read',
  OrgUsersAdd: 'org.users:add',
  OrgUsersRemove: 'org.users:remove',
  OrgUsersWrite: 'org.users:write',

  ActionRolesList: 'roles:read',
  ActionUserRolesList: 'users.roles:read',
  ActionUserRolesAdd: 'users.roles:add',
  ActionUserRolesRemove: 'users.roles:remove',

  ActionAPIKeysRead: 'apikeys:read',
  ActionAPIKeysCreate: 'apikeys:create',
  ActionAPIKeysDelete: 'apikeys:delete',

  SettingsRead: 'settings:read',
  SettingsWrite: 'settings:write',
  ServerStatsRead: 'server.stats:read',
};

const useAccessControl = () => {
  const { userProfile } = useProfile();

  const hasPermission = (action) => {
    return !!userProfile?.permissions?.[action];
  };
  const evaluatePermission = (actions) => {
    if (actions.every(action => hasPermission(action))) {
      return [];
    }
    return ['Reject'];
  };
  return { hasPermission, evaluatePermission };
};

function evaluateAccess(actions) {
  return () => {
    const {evaluatePermission}  = useAccessControl();
    return evaluatePermission(actions);
  };
}

export { useAccessControl, AccessControlAction, evaluateAccess };