import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Card, Form, Input, Button, Row, Col } from 'antd';
import { GoogleOutlined } from '@ant-design/icons';

import usecustomStyles from '../../Common/customStyles';
import ParticleAuth from '../../Common/ParticleAuth';
import { bootData } from '../../config';
import withRouter from '../../Common/withRouter';

import { login, socialLogin } from "../../slices/thunk";

const customStyles = usecustomStyles();
const StyleLine = styled.div`
.signin-other-title {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        border-top: 1px dashed #eff2f7;
        top: 10px;
    }
    .title {
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: #d0d0d0;
        padding: 2px 16px;
    }
  }
`;
const StyleWrapper = styled.div`
    background-color: ${({ theme }) => theme.token.authbgcolor};
`

const LoginPage = (props) => {
  document.title = "Login-" + bootData.settings.appTitle;

  const registerUrl = bootData.settings.verifyEmailEnabled ? "/verify" : "/signup";
  const dispatch = useDispatch();

  const selectLogin = createSelector(
      (state) => state.Login,
      (login) => ({
          loading: login.loading,
      })
  );
  const { loading } = useSelector(selectLogin);

  //Initialise Forms
  const [loginForm] = Form.useForm();
  useEffect(() => {
    loginForm.setFieldsValue({
      user: '',
      password: '',
    });
  }, [loginForm]);

  //Submit Forms
  const handleLoginSubmit = (values) => {
    dispatch(login(values));
    loginForm.resetFields();
  }

  const signIn = (type) => {
      dispatch(socialLogin(type, props.router.navigate));
  };
  
  return (
    <React.Fragment>
      <StyleWrapper className="auth-page-wrapper">
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Col xs={24} lg={14} >
            <Card>
              <Row gutter={[16, 24]}>
                <ParticleAuth />
                <Col xs={24} lg={12} >
                  <div style={{ border: "0px" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                      <h5 style={{ fontSize: "20px", color: customStyles.colorPrimary }}>Welcome Back!</h5>
                      <p>Sign in to continue to Lizant.</p>
                    </div>
                    <div style={{ marginTop: "30px", padding: "40px" }} >
                      <Form form={loginForm} name="signup" onFinish={handleLoginSubmit}>
                        <div>
                          <label style={{ display: "block", marginBottom: "4px" }}>Username/Email</label>
                          <Form.Item name="user" rules={[
                            { required: true, message: "Username/Email is required" },
                          ]}>
                            <Input name="user" type="text" placeholder="Enter username/email" style={{ boxShadow: "none" }}/>
                          </Form.Item>
                        </div>
                        <div>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <label style={{display:'block', marginBottom:'4px'}}>Password</label>
                            <Link to="/forgot-password" style={{fontSize:'11px'}}>Forgot password?</Link>
                          </div>
                          <Form.Item name="password" rules={[{ required: true, message: "Password is required" }]}>
                            <Input.Password name="password" placeholder="Enter password" style={{ boxShadow: "none" }}/>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item>
                            <Button type='primary' htmlType="submit" loading={loading} style={{ width: "100%" }}>Sign In</Button>
                          </Form.Item>
                        </div>
                        <div style={{ paddingTop: "18px" }} className="text-center">
                          <StyleLine className="signin-other-title"><h5 style={{ fontSize: "13px" }}>Sign In with</h5></StyleLine>
                          <div style={{ paddingTop: "25px" }}>
                            <Button  style={{ height: "50px", width: "50px", backgroundColor: customStyles.colorDangerBg, marginRight: "8px", color: customStyles.colorDanger, border: "0px" }}
                              onClick={e => { e.preventDefault(); signIn("google"); }}><GoogleOutlined /></Button>
                          </div>
                        </div>
                      </Form>
                      <div style={{ marginTop: "50px" }} className="text-center">
                        <p>Don't have an account?  <Link to={registerUrl} style={{ marginRight: "5px", textDecoration: "underline", color: customStyles.colorSecondary, fontWeight: "bold" }}>Sign Up</Link></p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <ToastContainer />
      </StyleWrapper>
    </React.Fragment>
  )
}

export default withRouter(LoginPage)