import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: true,
  org: {},
  users: [],
  totalUsersCount: 0,
  page: 1,
  perPage: 10,
};

const orgSlice = createSlice({
  name: "Org",
  initialState,
  reducers: {
    orgSuccess(state, action) {
      state.org = action.payload
      state.loading = false
    },
    orgUsersSuccess(state, action) {
      state.users = action.payload.orgUsers
      state.totalUsersCount = action.payload.totalCount
      state.page = action.payload.page
      state.perPage = action.payload.perPage
      state.loading = false
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
  },
});

export const {
  orgSuccess,
  orgUsersSuccess,
  setLoading,
} = orgSlice.actions

export default orgSlice.reducer;