import { getFakeOrg, getFakeOrgUsers, removeFakeUserFromOrg, updateFakeUserOrgRole, updateFakeOrg } from "../../../helpers/fakebackend_helper";
import { orgSuccess, orgUsersSuccess, setLoading } from "./reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getOrg = (id) => async (dispatch) => {
    try {
        let response;
        dispatch(setLoading(true));
        response = getFakeOrg(id);
        const data = await response;
        if (data) {
          dispatch(orgSuccess(data));
        }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to get org: " + error.message, { autoClose: 3000 });
    }
};

export const updateOrg = (values) => async (dispatch) => {  
  try {
      let response;
      dispatch(setLoading(true));
      response = updateFakeOrg(values);
      const data = await response;
      dispatch(getOrg(values.id));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update org: " + error.message, { autoClose: 3000 });
  }
}

export const getOrgUsersSearch = (orgId, query) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = getFakeOrgUsers(orgId, query);
      const data = await response;
      if (data) {
        dispatch(orgUsersSuccess(data));
      }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get org users: " + error.message, { autoClose: 3000 });
  }
}

export const removeOrgUser = (values) => async (dispatch) => {
  try {
      let response;
      response = removeFakeUserFromOrg(values);
      const data = await response;
      const query = {page: 1, perpage: 10, query: ''};
      dispatch(getOrgUsersSearch(values.orgId, query));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to remove org user: " + error.message, { autoClose: 3000 });
  }
}

export const updateOrgUserRole = (values) => async (dispatch) => {  
  try {
      let response;
      dispatch(setLoading(true));
      response = updateFakeUserOrgRole(values);
      const data = await response;
      const query = {page: 1, perpage: 10, query: ''};
      dispatch(getOrgUsersSearch(values.orgId, query));
      toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update user org role: " + error.message, { autoClose: 3000 });
  }
}