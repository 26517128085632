import React, { useEffect, } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { ToastContainer } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard'

import { Table, Button, message, Space, Tag, Row, Col, Card, Popconfirm } from 'antd';
import { Mail, Trash2, Users } from "lucide-react";
import { CopyOutlined } from '@ant-design/icons';

import Breadcrumb from '../../Common/Breadcrumb';
import usecustomStyles from '../../Common/customStyles';
import { bootData } from "../../config";

import { getOrgInvites, revokeOrgInvites } from "../../slices/thunk";

const customStyles = usecustomStyles();

const InvitesPage = () => {
  document.title = "Invites-"  + bootData.settings.appTitle;

  const dispatch = useDispatch();

  const selectOrgInvitesData = createSelector(
    (state) => state.OrgInvites,
    (orgInvites) => ({
        invites: orgInvites.invites,
        totalInvites: orgInvites.totalInvites,
    })
  );
  const { invites, totalInvites } = useSelector(selectOrgInvitesData);

  useEffect(() => {
    if (totalInvites === 0) {
      dispatch(getOrgInvites());
    }
  }, [dispatch, totalInvites]);

  //Table Columns
  const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Invited By',
        dataIndex: 'invitedByLogin',
        sorter: (a, b) => a.invitedByLogin.localeCompare(b.invitedByLogin),
        sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Role',
      align: 'center',
      dataIndex: 'role',
      sorter: (a, b) => a.role.localeCompare(b.role),
      sortDirections: ['ascend', 'descend'],
      render: (text) => (
        <Tag color={text === 'ADMIN' ? 'red' : 'green'}>{text}</Tag>
      ),
    },
    {
      title: 'Email Sent',
      align: 'center',
      dataIndex: 'emailSent',
      render: (text) => ( <Mail size={16} color={text === true ? 'success' : 'red'} />),
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ['ascend', 'descend'],
    },
    {
        title: 'Created On',
        align: 'center',
        dataIndex: 'createdOn',
        sorter: (a, b) => a.createdOn.localeCompare(b.createdOn),
        sortDirections: ['ascend', 'descend'],
        render: (text) => (
          <span>{new Date(text).toLocaleDateString()}</span>
        ),
    },
    {
        title: 'Actions',
        align: 'center',
        render: (_, record) => (
          <Space>
              <CopyToClipboard text={record.url} onCopy={handleCopyToClipboard}>
                <Button icon={<CopyOutlined />} size="small"/>
              </CopyToClipboard>
              <Popconfirm title="Revoke invite" description="Are you sure to revoke?" onConfirm={() => dispatch(revokeOrgInvites(record.code))}  okText="Revoke" cancelText="Cancel">
                <Button icon={<Trash2 size={12} />} size="small" danger/>
              </Popconfirm>
          </Space>
        )
    },
  ];

  //Helper Functions
  const handleCopyToClipboard = () => {
    message.success('Copied.');
  };

  return (
    <React.Fragment>
        <Breadcrumb mainTitle="Administration" pageTitle="Invites" />
        <Row gutter={[24]}>
            <Col lg={24} xl={24}>
                <Card style={{ marginBottom: customStyles.margin }} >
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: '10px' }}>
                        <div>
                          <Button type='primary' href='/org/users' style={{ boxShadow:'none' }}>
                            <Users size={16}/>Members
                          </Button>
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
        <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}>
          <Table columns={columns} dataSource={(invites || []).map((invite, index) => ({
                ...invite,
                key: index,
            }))}
            pagination={{
              pageSize: 10,
              total: totalInvites,
              hideOnSinglePage: true
            }}
          />
        </div>
        <ToastContainer />
    </React.Fragment>
  )
}

export default InvitesPage