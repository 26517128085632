//PROFILE/CURRENT USER
export const USER = "/api/user";

//CURRENT ORG
export const CURRENT_ORG = "/api/org";
export const CURRENT_ORG_USERS = "/api/org/users";
export const CURRENT_ORG_INVITES = "/api/org/invites";

//SIGNUP
export const POST_FAKE_VERIFY_EMAIL = "/api/user/signup";
export const POST_FAKE_SIGNUP = "/api/user/signup/step2";
export const USER_INVITE = "/api/user/invite";

//LOGIN
export const POST_FAKE_LOGIN = "/login";
export const GET_FAKE_LOGOUT = "/logout";
export const POST_FAKE_PASSWORD_FORGET = "/api/user/password/send-reset-email";
export const POST_FAKE_PASSWORD_RESET = "/api/user/password/reset";

//USERS
export const ADMIN_USERS = "/api/admin/users";
export const FAKE_USERS = "/api/users";

//ORGS
export const ORGS = "/api/orgs";

//SETTINGS
export const SETTINGS = "/api/admin/settings";
export const STATS = "/api/admin/stats";

//SERVICEACCOUNTS
export const SERVICE_ACCOUNTS = "/api/serviceaccounts";
