import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import countryList from 'react-select-country-list'

import { Card, Col, Form, Input, Row, Button, Select, Space } from "antd";
import { ToastContainer } from 'react-toastify';

import Breadcrumb from "../../Common/Breadcrumb";
import usecustomStyles from "../../Common/customStyles";
import { bootData } from "../../config";

import { getCurrentOrg, updateCurrentOrg, updateCurrentOrgAddress } from "../../slices/thunk";

const customStyles = usecustomStyles();


const CurrentOrgPage = () => {
  document.title = "Organization Profile-" + bootData.settings.appTitle;
  const countries = useMemo(() => countryList().getData(), [])
  const dispatch = useDispatch();

  const selectCurrentOrg = createSelector(
    (state) => state.CurrentOrg,
    (currentOrg) => ({
      org: currentOrg.org,
      loading: currentOrg.loading,
    })
  );
  const { org, loading } = useSelector(selectCurrentOrg);
  
  useEffect(() => {
    dispatch(getCurrentOrg());
  }, [dispatch]);

  //Initialise Forms
  const [orgForm] = Form.useForm();
  const [addressForm] = Form.useForm();
  useEffect(() => {
    orgForm.setFieldsValue({
      name: org.name || "",
    });
  }, [orgForm, org]);
  useEffect(() => {
    addressForm.setFieldsValue({
      address1: (org.address && org.address.address1) || "",
      address2: (org.address && org.address.address2) || "",
      city: (org.address && org.address.city) || "",
      state: (org.address && org.address.state) || "",
      country: (org.address && org.address.country) || "",
      zipcode: (org.address && org.address.zipcode) || "",
    });
  }, [addressForm, org]);

  //Submit Forms
  const updateOrgSubmit = (values) => {
    dispatch(updateCurrentOrg(values));
  };
  const updateOrgAddressSubmit = (values) => {
    dispatch(updateCurrentOrgAddress(values));
  };

  return (
    <React.Fragment>
      <div>
        <Breadcrumb mainTitle="Organization " pageTitle="Profile"/>
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form style={{ width: '100%'}} form={orgForm} name="update-org" onFinish={updateOrgSubmit}>
                  <div>
                    <Space.Compact style={{ width: '100%'}} >
                      <Form.Item style={{ display: 'block', marginBottom: "0", width: '100%'}} name="name" rules={[{ required: true, message: "Name is required" }]} >
                        <Input  name="name" type="text" placeholder="Enter name" style={{ boxShadow: "none" }}/>
                      </Form.Item>
                      <Button type="primary" htmlType="submit" loading={loading}>Update</Button>
                    </Space.Compact>
                  </div>
                </Form>
              </div>
            </Card>
            <Card style={{ marginBottom: customStyles.margin }}>
            <Form layout={'horizontal'} labelCol={{flex: '200px'}} wrapperCol={{flex: 1}} style={{ maxWidth: 'none' }} form={addressForm} name="update-address" onFinish={updateOrgAddressSubmit}>
              <div>
                <Form.Item label="Address1" name="address1" rules={[{ required: true, message: "Address1 is required" }]}>
                  <Input name="address1" type="text" placeholder="Enter address" style={{ boxShadow: "none" }}/>
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Address2" name="address2">
                  <Input name="address2" type="text" placeholder="Enter address" style={{ boxShadow: "none" }}/>
                </Form.Item>
              </div>
              <div>
                <Form.Item label="City" name="city" rules={[{ required: true, message: "City is required" }]}>
                  <Input name="city" type="text" placeholder="Enter city" style={{ boxShadow: "none" }}/>
                </Form.Item>
              </div>
              <div>
                <Form.Item label="State" name="state" rules={[{ required: true, message: "State is required" }]}>
                  <Input name="state" type="text" placeholder="Enter state" style={{ boxShadow: "none" }}/>
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Country" name="country"rules={[{ required: true, message: "Country is required" }]}>
                  <Select showSearch name="country" placeholder="Select a country" options={countries}
                    filterOption = {(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item label="Zip Code" name="zipcode"rules={[{ required: true, message: "Zip code is required" }]}>
                  <Input name="zipcode" type="text" placeholder="Enter zip code" style={{ boxShadow: "none" }}/>
                </Form.Item>
              </div>
              <div className="text-center">
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>Update</Button>
                </Form.Item>
              </div>
            </Form>
            </Card>
          </Col>
        </Row>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default CurrentOrgPage;
