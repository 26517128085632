import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

//redux
import withRouter from "../../Common/withRouter";
import { bootData } from "../../config";

import { logout } from "../../slices/thunk";

const LogoutPage = () => {
  document.title = "Logout-" + bootData.settings.appTitle;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  return <React.Fragment></React.Fragment>;
};

export default withRouter(LogoutPage);