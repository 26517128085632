import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux';
import { Navigate } from "react-router-dom";
import { useProfile } from '../Common/Hooks/UserHooks';
import { logout } from '../slices/thunk';
import { useAccessControl } from "../Common/Hooks/AccessControl"; 

const AuthProtected = ({ actions, children }) => {
    const dispatch = useDispatch();
    const { evaluatePermission } = useAccessControl();
    const { userProfile } = useProfile();

    const hasRole = useCallback((role) => {
      if (role === 'SuperAdmin') {
        return userProfile.isGrafanaAdmin;
      } else {
        return userProfile.orgRole === role;
      }
    }, [userProfile]);

    useEffect(() => {
      if (!userProfile) {
        dispatch(logout());
      }
    }, [ userProfile, dispatch]);
  
    if (actions) {
      const roles = evaluatePermission(actions);
      if (roles?.length) {
        if (!roles.some((r) => hasRole(r))) {
          return (<Navigate to={{ pathname: "/403"}} />);
        }
      }
    }
    if (!userProfile) {
      return (<Navigate to={{ pathname: "/login"}} />);
    }
    return <>{children}</>;
}

export default AuthProtected
