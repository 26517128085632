import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

import { Button, Card, Col, Form, Input, Row } from 'antd';

import usecustomStyles from '../../Common/customStyles';
import ParticleAuth from '../../Common/ParticleAuth';
import withRouter from '../../Common/withRouter';
import { bootData } from '../../config';

import { getUserInvite, signupInvitedUser } from "../../slices/thunk";

const customStyles = usecustomStyles();
const StyleWrapper = styled.div`
  background-color: ${({ theme }) => theme.token.authbgcolor};
`
const InvitedSignupPage = (props) => {
  document.title = "Signup-" + bootData.settings.appTitle;

  const dispatch = useDispatch();
  let { code } = props.router.params;

  const selectInvitedSignup = createSelector(
    (state) => state.Signup,
    (signup) => ({
      success: signup.success,
      invite: signup.invite,
      loading: signup.loading,
    })
  );
  const { success, invite, loading } = useSelector(selectInvitedSignup);

  useEffect(() => {
    if (code && code !== "") {
      dispatch(getUserInvite(code));
    }
  }, [dispatch, code])
  
  //Initialise timer functions to redirect
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    if (success) {
        setTimeout(() => props.router.navigate("/login"), 1000);
        setTimer(3)
    }
  }, [dispatch, success, props.router]);
  useEffect(() => {
    if (timer) {
      setInterval(() => setTimer(timer - 1), 1000);
    }
  }, [timer])

  //Initialise Forms
  const [signupForm] = Form.useForm();
  useEffect(() => {
    if (invite) {
      signupForm.setFieldsValue({
        name: invite.name || "",
        username: invite.username || "",
        email: invite.email || "",
        password: "",
        confirmPassword: ""
      });
    }
  }, [signupForm, invite]);

  //Validate Passwords
  const validPassword = Form.useWatch((values) => (values.password === values.confirmPassword ? true : false), signupForm);
  const validateConfirmPassword = () => {
    if (validPassword) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("The two passwords that you entered do not match!")
    );
  };

  //Submit Forms
  const handleSignupSubmit = (values) => {
    dispatch(signupInvitedUser({...values, inviteCode: code}));
    signupForm.resetFields();
  };

  return (
    <StyleWrapper className="auth-page-wrapper">
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xs={24} lg={14}>
          <Card>
            <Row gutter={[16, 24]}>
              <ParticleAuth />
              <Col xs={24} lg={14} >
                <Card style={{ border: "0px" }}>
                  <div className="text-center" style={{ margin: "20px" }}>
                    <h5 style={{ fontSize: "20px", color: customStyles.colorPrimary }}>Create New Account</h5>
                  </div>
                  <div style={{ marginTop: "30px", padding: "40px" }} >
                    <Form form={signupForm} name="invited-registration" onFinish={handleSignupSubmit}  size={"small"}>
                      <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Name</label>
                        <Form.Item name="name" rules={[{ required: true, message: "Name is required" }]}>
                          <Input name="name" type="text" placeholder="Enter name" style={{ boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Username</label>
                        <Form.Item name="username" rules={[{ required: true, message: "Username is required" }]}>
                          <Input name="username" type="text" placeholder="Enter username" style={{ boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <label style={{ display: "block", marginBottom: "4px" }} htmlFor="email">Email</label>
                        <Form.Item name="email" rules={[{ required: true, message: "Email is required" }]}>
                          <Input name="email" type="email" placeholder="Enter email" style={{ boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <label style={{ marginBottom: "4px", display: "block" }}>Password</label>
                        <Form.Item name="password" rules={[
                          { required: true, message: "Password is required" },
                          { pattern: new RegExp(`(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,}`), message: "Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"},
                        ]}>
                          <Input.Password placeholder="Enter password" style={{ outline: "none", boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <label style={{ marginBottom: "4px", display: "block" }}>Confirm Password</label>
                        <Form.Item name="confirmPassword" rules={[{ validator: validateConfirmPassword }]}>
                          <Input.Password placeholder="Enter password" style={{ outline: "none", boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div className="text-center">
                        <Form.Item>
                          <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }} loading={loading} >Sign Up</Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </StyleWrapper>
  )
}

export default withRouter(InvitedSignupPage);