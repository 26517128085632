import { updateFakeCurrentUser, getFakeCurrentUser, updateFakeCurrentUserPassword, getFakeProfileOrgs, getFakeLoggedinUserSessions, revokeFakeLoggedinUserSession} from "../../../helpers/fakebackend_helper";
import { profileSuccess, profileOrgsSuccess, setLoading, profileAuthTokensSuccess } from "./reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getProfile = () => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = getFakeCurrentUser();
    const data = await response;
    if (data) {
      dispatch(profileSuccess(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get profile: " + error.message, { autoClose: 3000 });
  }
};

export const getProfileOrgs = () => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = getFakeProfileOrgs();
    const data = await response;
    if (data) {
      dispatch(profileOrgsSuccess(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get profile orgs: " + error.message, { autoClose: 3000 });
  }
};

export const updateProfile = (user) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = updateFakeCurrentUser(user);
    const data = await response;
    dispatch(getProfile());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update profile: " + error.message, { autoClose: 3000 });
  }
};

export const changePassword = (values) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = updateFakeCurrentUserPassword(values);
    const data = await response;
    dispatch(getProfile());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to change password: " + error.message, { autoClose: 3000 });
  }
};

export const getProfileSessions = () => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = getFakeLoggedinUserSessions();
    const data = await response;
    if (data) {
      dispatch(profileAuthTokensSuccess(data));
    }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get sessions: " + error.message, { autoClose: 3000 });
  }
}

export const revokeProfileSession = (values) => async (dispatch) => {
  try {
    let response;
    response = revokeFakeLoggedinUserSession(values);
    const data = await response;
    dispatch(getProfileSessions());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to revoke session: " + error.message, { autoClose: 3000 });
  }
}