import { postFakeVerifyEmail } from "../../../helpers/fakebackend_helper";
import { setLoading, verifyEmailSuccess } from "./reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const verifyEmail = (email) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = postFakeVerifyEmail(email);
    const data = await response;
    dispatch(verifyEmailSuccess());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to verify email: " + error.message, { autoClose: 3000 });
  }
};
