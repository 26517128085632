import { getFakeSettings, getFakeStats } from "../../../helpers/fakebackend_helper";
import { settingsSuccess, statsSuccess, setLoading } from "./reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getSettings = () => async (dispatch) => {
    try {
        let response;
        dispatch(setLoading(true));
        response = getFakeSettings();
        const data = await response;
        if (data) {
          dispatch(settingsSuccess(data));
        }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to get settings: " + error.message, { autoClose: 3000 });
    }
};

export const getStats = () => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = getFakeStats();
      const data = await response;
      if (data) {
        dispatch(statsSuccess(data));
      }
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to get stats: " + error.message, { autoClose: 3000 });
  }
};