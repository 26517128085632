import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { Button, Card, Col, Form, Input, Row } from 'antd';
import { Mail } from 'lucide-react';

import ParticleAuth from '../../Common/ParticleAuth';
import usecustomStyles from '../../Common/customStyles';
import { bootData } from '../../config';

import { forgotPassword } from '../../slices/thunk';

const customStyles = usecustomStyles();
const StyleWrapper = styled.div`
    background-color: ${({ theme }) => theme.token.authbgcolor};
`

const ForgotPasswordPage = () => {
  document.title = "Forget Password-" + bootData.settings.appTitle;

  const dispatch = useDispatch();
  const selectForgotPassword = createSelector(
    (state) => state.ForgotPassword,
    (forgetPassword) => ({
      loading: forgetPassword.loading,
    })
  );
  const { loading } = useSelector(selectForgotPassword);

  //Initialise Forms
  const [forgotPasswordForm] = Form.useForm();
  useEffect(() => {
    forgotPasswordForm.setFieldsValue({
      userOrEmail: '',
    });
  }, [forgotPasswordForm]);

  //Submit Forms
  const handleForgotPasswordSubmit = (values) => {
    dispatch(forgotPassword(values));
    forgotPasswordForm.resetFields();
  }

  return (
    <React.Fragment>
      <StyleWrapper className="auth-page-wrapper">
        <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
          <Col xs={24} lg={16}>
            <Card>
              <Row gutter={[16, 24]}>
                <ParticleAuth />
                <Col xs={24} lg={14} >
                  <Card style={{ border: "0px" }}>
                    <div className="text-center" style={{ margin: "20px" }}>
                      <h5 style={{ fontSize: "20px", color: customStyles.colorPrimary }}>Forgot Password?</h5>
                      <p>Reset password with Lizant</p>
                      <div>
                        <Mail size={50} style={{ color: customStyles.colorDanger, marginTop: "20px" }} />
                      </div>
                    </div>
                    <div style={{ marginTop: "0px", padding: "20px 40px" }} >
                      <Form form={forgotPasswordForm} name="signup" onFinish={handleForgotPasswordSubmit} >
                        <div>
                          <label style={{ display: "block", marginBottom: "4px" }}>Email</label>
                          <Form.Item name="userOrEmail" style={{ marginBottom: "12px" }} rules={[{ required: true, message: "Email is required" }]}>
                            <Input name="userOrEmail" type="text" placeholder="Enter email" style={{ boxShadow: "none" }}/>
                          </Form.Item>
                        </div>
                        <div>
                          <Form.Item>
                            <Button type='primary' htmlType="submit" loading={loading} style={{ width: "100%" }}>Send Reset Link</Button>
                          </Form.Item>
                        </div>
                      </Form>
                      <div style={{ marginTop: "50px" }} className="text-center">
                        <p><a href="/login" style={{ marginRight: "5px", textDecoration: "underline", fontWeight: "bold" }}>Sign In</a></p>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </StyleWrapper>
      <ToastContainer />
    </React.Fragment>
  )
}

export default ForgotPasswordPage