import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  invites: [],
  showInvites: false,
  totalInvites: 0,
  page: 1,
};

const orgInvitesSlice = createSlice({
  name: "OrgInvites",
  initialState,
  reducers: {
      getOrgInvitesSuccess(state, action) {
          state.invites = action.payload
          state.totalInvites = action.payload.length
          state.showInvites = action.payload.length > 0
      }
  },
});

export const {
  getOrgInvitesSuccess
} = orgInvitesSlice.actions

export default orgInvitesSlice.reducer;
