import { usersSuccess, setLoading } from "./reducer"
import { getFakeCurrentOrgUsers, postFakeUserInvite, removeFakeCurrentOrgUser, updateFakeCurrentOrgUser } from "../../../helpers/fakebackend_helper";
import { getOrgInvites } from "../invites/thunk";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getCurrentOrgUsers = (query) => async (dispatch) => {
  try {
      let response;
      dispatch(setLoading(true));
      response = getFakeCurrentOrgUsers(query);
      const data = await response;
      if (data) {
          dispatch(usersSuccess(data));
      }
  } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to get users: " + error.message, { autoClose: 3000 });
  }
}

export const updateCurrentOrgUser = (values) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = updateFakeCurrentOrgUser(values);
    const data = await response;
    const query = {page: 1, perpage: 10, query: '', accesscontrol: false};
    dispatch(getCurrentOrgUsers(query));
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to update user: " + error.message, { autoClose: 3000 });
  }
}

export const removeCurrentOrgUser = (id) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = removeFakeCurrentOrgUser(id);
    const data = await response;
    const query = {page: 1, perpage: 10, query: '', accesscontrol: false};
    dispatch(getCurrentOrgUsers(query));
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to remove user: " + error.message, { autoClose: 3000 });
  }
}

export const inviteUser = (values) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = postFakeUserInvite(values);
    const data = await response;
    const query = {page: 1, perpage: 10, query: '', accesscontrol: false};
    dispatch(getCurrentOrgUsers(query));
    dispatch(getOrgInvites());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to invite user: " + error.message, { autoClose: 3000 });
  }
}