import { combineReducers } from "@reduxjs/toolkit";
import ProfileReducer from "./user/profile/reducer";
import CurrentOrgUsersReducer from "./org/users/reducer";
import CurrentOrgReducer from "./org/org/reducer";
import OrgInvitesReducer from "./org/invites/reducer";
import VerifyEmailReducer from "./auth/verifyemail/reducer";
import SignupReducer from "./auth/signup/reducer";
import LoginReducer from "./auth/login/reducer";
import ForgotPasswordReducer from "./auth/forgotpassword/reducer";
import UsersReducer from "./admin/users/reducer";
import UserReducer from "./admin/user/reducer";
import OrgsReducer from "./admin/orgs/reducer";
import OrgReducer from "./admin/org/reducer";
import SettingsStatsReducer from "./admin/settings_stats/reducer";
import ServiceAccountsReducer from "./org/serviceaccounts/reducer";

const rootReducer = combineReducers ({
  //User
  Profile: ProfileReducer,

  //Current Org
  CurrentOrgUsers: CurrentOrgUsersReducer,
  CurrentOrg: CurrentOrgReducer,
  OrgInvites: OrgInvitesReducer,
  ServiceAccounts: ServiceAccountsReducer,

  //Auth
  VerifyEmail: VerifyEmailReducer,
  Signup: SignupReducer,
  Login: LoginReducer,
  ForgotPassword: ForgotPasswordReducer,

  //Admin
  Users: UsersReducer,
  User: UserReducer,
  Orgs: OrgsReducer,
  Org: OrgReducer,
  SettingsStats: SettingsStatsReducer,
});

export default rootReducer