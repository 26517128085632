import { postFakeSignup, postFakeInvitedSignup, getInvitedUser} from "../../../helpers/fakebackend_helper";

import { signupSuccessful, inviteSuccess, setLoading } from "./reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const signupUser = (values) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = postFakeSignup(values);
    const data = await response;
    dispatch(signupSuccessful());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(false));
    toast.error("Failed to signup: " + error.message, { autoClose: 3000 });
  }
};

export const signupInvitedUser = (query) => async (dispatch) => {
  try {
    let response;
    dispatch(setLoading(true));
    response = postFakeInvitedSignup(query);
    const data = await response;
    dispatch(signupSuccessful());
    toast.success(data.message, { autoClose: 3000 });
  } catch (error) {
    dispatch(setLoading(true));
    toast.error("Failed to signup: " + error.message, { autoClose: 3000 });
  }
}

export const getUserInvite = (code) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = getInvitedUser(code);
    const data = await response;
    dispatch(inviteSuccess(data));
  } catch (error) {
    dispatch(setLoading(true));
    toast.error("Failed to invite: " + error.message, { autoClose: 3000 });
  }
}