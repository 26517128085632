import React, { useEffect, useState } from 'react'
import { createSelector } from 'reselect';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { Button, Card, Col, Form, Input, Row } from 'antd';
import ParticleAuth from '../../Common/ParticleAuth';
import { GoogleOutlined } from '@ant-design/icons';

import withRouter from '../../Common/withRouter';
import { bootData } from '../../config';
import usecustomStyles from '../../Common/customStyles';

import { verifyEmail } from "../../slices/thunk";

const customStyles = usecustomStyles();
const StyleLine = styled.div`
.signin-other-title {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        right: 0;
        border-top: 1px dashed #eff2f7;
        top: 10px;
    }
    .title {
        display: inline-block;
        position: relative;
        z-index: 9;
        background-color: #d0d0d0;
        padding: 2px 16px;
    }
  }
`;
const StyleWrapper = styled.div`
    background-color: ${({ theme }) => theme.token.authbgcolor};
`

const VerifyEmailPage = (props) => {
  document.title = "Verify Email-" + bootData.settings.appTitle;

  const dispatch = useDispatch();

  const selectVerifyEmail = createSelector(
    (state) => state.VerifyEmail,
    (verifyEmail) => ({
      loading: verifyEmail.loading,
      success: verifyEmail.success,
    })
  );
  const { loading, success } = useSelector(selectVerifyEmail);

  //Initialise timer functions to redirect
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    if (success) {
      setTimeout(() => props.router.navigate("/signup"), 3000);
      setTimer(3)
    }
  }, [dispatch, success, props.router]);
  useEffect(() => {
    if (timer) {
      setInterval(() => setTimer(timer - 1), 1000);
    }
  }, [timer])

  //Initialise Forms
  const [emailForm] = Form.useForm();
  useEffect(() => {
    emailForm.setFieldsValue({
      email: '',
    });
  }, [emailForm]);

  //Submit Forms
  const handleVerifyEmailSubmit = (values) => {
    dispatch(verifyEmail(values));
    emailForm.resetFields();
  }

  return (
    <StyleWrapper className="auth-page-wrapper">
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xs={24} lg={14}>
          <Card>
            <Row gutter={[16, 24]}>
              <ParticleAuth />
              <Col xs={24} lg={14} >
                <Card style={{ border: "0px" }}>
                  <div className="text-center" style={{ margin: "20px" }}>
                    <h5 style={{ fontSize: "20px", color: customStyles.colorPrimary }}>Create new account</h5>
                    <p>Verify your email now.</p>
                  </div>
                  <div style={{ marginTop: "30px", padding: "40px" }} >
                    <Form form={emailForm} name="verify-email" onFinish={handleVerifyEmailSubmit}  size={"small"}>
                      <div>
                        <label style={{ display: "block", marginBottom: "4px" }}>Email</label>
                        <Form.Item name="email" rules={[
                          { required: true, message: "Email is required" },
                          { pattern: new RegExp(`^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$`), message: "Email is not valid" },
                        ]}>
                          <Input name="email" type="email" placeholder="Enter email" style={{ boxShadow: "none" }}/>
                        </Form.Item>
                      </div>
                      <div>
                        <p style={{ fontSize: "12px", fontStyle: "italic", marginBottom: "10px" }}>Get a verification link sent to you.</p>
                      </div>
                      <div>
                        <Form.Item>
                          <Button type='primary' htmlType="submit" loading={loading}>Send verification email</Button>
                        </Form.Item>
                      </div>
                      <div style={{ paddingTop: "18px" }} className='text-center'>
                        <StyleLine className="signin-other-title">
                          <h5 style={{ fontSize: "13px" }}>Create account with</h5>
                        </StyleLine>
                        <div style={{ paddingTop: "25px" }}>
                          <Button style={{ height: "50px", width: "50px", backgroundColor: customStyles.colorDangerBg, marginRight: "8px", color: customStyles.colorDanger, border: "0px" }}><GoogleOutlined /></Button>
                        </div>
                      </div>
                    </Form>
                    <div style={{ marginTop: "50px" }} className="text-center">
                      <p>Already have an account ?  <a href="/login" style={{ marginRight: "5px", textDecoration: "underline", fontWeight: "bold" }}>Sign In</a></p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <ToastContainer />
    </StyleWrapper>
  )
}

export default withRouter(VerifyEmailPage);