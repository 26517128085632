import { Navigate } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import ProfilePage from "../pages/User/profile";
import CurrentOrgUsersPage from "../pages/Org/currentorgusers";
import CurrentOrgPage from "../pages/Org/currentorg";
import InvitesPage from "../pages/Org/invites";
import LoginPage from "../pages/Authentication/login";
import LogoutPage from "../pages/Authentication/logout";
import SignupPage from "../pages/Authentication/signup";
import VerifyEmailPage from "../pages/Authentication/verifyemail";
import ForgotPasswordPage from "../pages/Authentication/forgotpassword";
import ResetPasswordPage from "../pages/Authentication/resetpassword";

import InvitedSignupPage from "../pages/Authentication/invitedsignup";
import FourhundredfourPage from "../pages/Error/404";
import { AccessControlAction } from "../Common/Hooks/AccessControl"; 
import UsersPage from "../pages/Administration/users";
import UserPage from "../pages/Administration/user";
import OrgsPage from "../pages/Administration/orgs";
import OrgPage from "../pages/Administration/org";
import FourhundredthreePage from "../pages/Error/403";
import SettingsPage from '../pages/Administration/settings';
import StatsPage from "../pages/Administration/stats";
import ServiceAccountsPage from "../pages/Org/serviceaccounts";
import ServiceAccountPage from "../pages/Org/serviceaccount";

const authProtectedRoutes = [
    { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
    { path: "/dashboard", exact: true, component: <Dashboard /> },
    { path: "/profile", exact: true, component: <ProfilePage /> },
    { path: "/org/profile", actions: [AccessControlAction.OrgsRead], exact: true, component: <CurrentOrgPage /> },
    { path: "/org/users", actions: [AccessControlAction.OrgUsersRead], exact: true, component: <CurrentOrgUsersPage /> },
    { path: "/org/users/invite", actions: [AccessControlAction.OrgUsersAdd], exact: true, component: <InvitesPage /> },
    { path: "/org/serviceaccounts", actions: [AccessControlAction.ServiceAccountsRead], exact: true, component: <ServiceAccountsPage /> },
    { path: "/org/serviceaccounts/:id", actions: [AccessControlAction.ServiceAccountsRead], exact: true, component: <ServiceAccountPage /> },
    { path: "/admin/users", actions: [AccessControlAction.UsersRead], exact: true, component: <UsersPage /> },
    { path: "/admin/users/:id", actions: [AccessControlAction.UsersRead], exact: true, component: <UserPage /> },
    { path: "/admin/orgs", actions: [AccessControlAction.OrgsRead, AccessControlAction.OrgsCreate], exact: true, component: <OrgsPage /> },
    { path: "/admin/orgs/:id", actions: [AccessControlAction.OrgsRead], exact: true, component: <OrgPage /> },
    { path: "/admin/settings", actions: [AccessControlAction.SettingsRead], exact: true, component: <SettingsPage /> },
    { path: "/admin/stats", actions: [AccessControlAction.ServerStatsRead], exact: true, component: <StatsPage /> },
    { path: "*",  component: <FourhundredfourPage /> },
];

const publicRoutes = [

    // Authentication
    { path: "/login", exact: true, component: <LoginPage /> },
    { path: "/logout", exact: true, component: <LogoutPage /> },
    { path: "/signup", exact: true, component: <SignupPage /> },
    { path: "/user/password/reset", exact: true, component: <ResetPasswordPage /> },
    { path: "/invite/:code", component: <InvitedSignupPage /> },
    { path: "/verify", exact: true, component: <VerifyEmailPage /> },
    { path: "/forgot-password", exact: true, component: <ForgotPasswordPage /> },
    { path: "/404", component: <FourhundredfourPage /> },
    { path: "/403", exact: true, component: <FourhundredthreePage /> },
    { path: "*",  component: <FourhundredfourPage /> },
]

export { publicRoutes, authProtectedRoutes };

