import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { ToastContainer } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard'

import { Card, Col, Form, Input, Row, Button, Radio, Badge, Space, Popconfirm, Table, Modal, DatePicker, message } from "antd";
import { StopOutlined, CopyOutlined } from '@ant-design/icons';
import { Trash2 } from 'lucide-react';

import Breadcrumb from "../../Common/Breadcrumb";
import withRouter from "../../Common/withRouter";
import Spinners from '../../Common/Spinner';
import { roles, formatDateTime } from "../../Common/data";
import usecustomStyles from "../../Common/customStyles";
import { bootData } from "../../config";

import { getServiceAccount, getServiceAccountTokens, updateServiceAccount, revokeServiceAccountToken, createServiceAccountToken } from "../../slices/thunk";
import { createServiceAccountTokenInitiated } from "../../slices/org/serviceaccounts/reducer";

const customStyles = usecustomStyles();

const ServiceAccountPage = (props) => {
  document.title = "Service Account-" + bootData.settings.appTitle;

  const dispatch = useDispatch();
  let { id } = props.router.params;
  const [expiration, setExpiration] = useState("No expiration");

  const selectServiceAccounts = createSelector(
    (state) => state.ServiceAccounts,
    (serviceAccounts) => ({
      serviceAccount: serviceAccounts.serviceAccount,
      loading: serviceAccounts.loading,
      tokens: serviceAccounts.tokens,
      token: serviceAccounts.token,
    })
  );
  const { serviceAccount, loading, tokens, token } = useSelector(selectServiceAccounts);
  
  useEffect(() => {
    dispatch(getServiceAccount(id));
    dispatch(getServiceAccountTokens(id));
  }, [dispatch, id]);

  //Modals
  const [createServiceAccountTokenModalShow, setCreateServiceAccountTokenModalShow] = useState(false)
  const toggleServiceAccountTokenModal = useCallback(() => 
    {
      dispatch(createServiceAccountTokenInitiated());
      setExpiration("No expiration");
      if (createServiceAccountTokenModalShow) {
         setCreateServiceAccountTokenModalShow(false)
      } else {
        setCreateServiceAccountTokenModalShow(true)
      }
    }, [dispatch, createServiceAccountTokenModalShow]);
  
  //Initialise Forms
  const [serviceAccountForm] = Form.useForm();
  const [createServiceAccountTokenForm] = Form.useForm();
  useEffect(() => {
    serviceAccountForm.setFieldsValue({
      name: serviceAccount.name || "",
      role: serviceAccount.role || "Viewer",
    });
  }, [serviceAccountForm, serviceAccount]);
  useEffect(() => {
    createServiceAccountTokenForm.setFieldsValue({
      name: "",
      secondsToLive: undefined,
    });
  }, [createServiceAccountTokenForm]);

  //Helper functions
  const getSecondsToLive = (date) => {
    const dateAsDate = new Date(date);
    const now = new Date();
    return Math.ceil((dateAsDate.getTime() - now.getTime()) / 1000);
  };
  const onExpirationChange = ({ target: { value } }) => {
    setExpiration(value);
  };
  const handleCopyToClipboard = () => {
    message.success('Copied.');
  };

  //Submit Forms
  const updateServiceAccountSubmit = (values) => {
    dispatch(updateServiceAccount(true, {...serviceAccount, name: values.name, role: values.role}));
  };
  const createServiceAccountTokenSubmit = (values) => {
    let secondsToLive = undefined;
    if (values.secondsToLive !== undefined) {
      secondsToLive = getSecondsToLive(values.secondsToLive);
    }
    dispatch(createServiceAccountToken({ ...values, id: id, secondsToLive: secondsToLive}));
    createServiceAccountTokenForm.resetFields();
  };
  const disableText = serviceAccount.isDisabled ? "Enable" : "Disable";

  //Table Columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Expires',
      dataIndex: 'expiration',
      render: (text, record) => {
        if (!text) {
          return (<span>Never</span>);
        }
        if (record.secondsUntilExpiration) {
          const days = Math.ceil(record.secondsUntilExpiration / (3600 * 24));
          const daysFormat = days > 1 ? `${days} days` : `${days} day`;
          return (
            <span style={{ color: customStyles.colorWarning }}>{`Expires in ${daysFormat}`}</span>
          );
        }
        if (record.hasExpired) {
          return (
            <span style={{ color: customStyles.colorDanger }}>Expired</span>
          );
        }
        return (<span>{formatDateTime(text)}</span>);
      }
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (text) => {
        if (!text) {
          return (<span>Never</span>);
        }
        return (<span>{formatDateTime(text)}</span>);
      }
    },
    {
      title: 'Last used at',
      dataIndex: 'lastUsedAt',
      render: (text) => {
        if (!text) {
          return (<span>Never</span>);
        }
        return (<span>{formatDateTime(text)}</span>);
      }   
      ,
    },
    {
      title: 'Actions',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Popconfirm title="Remove token" description="Are you sure to revoke?" onConfirm={() => dispatch(revokeServiceAccountToken({id: id, tokenId: record.id}))}  okText="Remove" cancelText="Cancel">
            <Button icon={<Trash2 size={12} />} size="small" danger/>
          </Popconfirm>
        </Space>
      )
    },
  ];

  return (
    <React.Fragment>
      <div>
        <Breadcrumb mainTitle="Organization" pageTitle="Service Account"/>
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }}>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100%", marginRight: "30px" }}>
                  {serviceAccount.isDisabled ? (
                    <Badge count={ <StopOutlined style={{ fontSize: '18px', color: customStyles.colorDanger, borderRadius: "50%" }} />} >
                      <img src={serviceAccount.avatarUrl} alt="" style={{ opacity: 0.5, height: "72px", width: "72px", borderRadius: "5px", marginBottom: customStyles.margin}}/>
                    </Badge>
                  ) : (
                    <img src={serviceAccount.avatarUrl} alt="" style={{  height: "72px", width: "72px", borderRadius: "5px", marginBottom: customStyles.margin}}/>
                  )}
                </div>
                <Form style={{ width: '100%'}} form={serviceAccountForm} name="update-serviceaccount" onFinish={updateServiceAccountSubmit}>
                  <div>
                    <label style={{ display: 'block', marginBottom: '4px' }}>Name</label>
                    <Form.Item name="name" rules={[{ required: true, message: "Name is required" }]} >
                      <Input  name="name" type="text" placeholder="Enter name" style={{ boxShadow: "none" }}/>
                    </Form.Item>
                  </div>
                  <div>
                    <label style={{ display: 'block', marginBottom: '4px' }}>Role</label>
                    <Form.Item name="role" initialValue="Viewer" rules={[{ required: true, message: 'Role is required' }]}>
                      <Radio.Group name="role" defaultValue="Viewer" options={roles} buttonStyle="solid" optionType="button"/>
                    </Form.Item>
                  </div>
                  <div className="text-center">
                    <Form.Item>
                      <Space>
                        <Popconfirm title={disableText} description="Are you sure?" onConfirm={() => {
                          dispatch(updateServiceAccount(true, {...serviceAccount, isDisabled: !serviceAccount.isDisabled}));
                        }}  okText={disableText} cancelText="Cancel">
                          <Button type="link" danger={!serviceAccount.isDisabled}>{disableText}</Button>
                        </Popconfirm>
                        <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }}>Update</Button>
                      </Space>
                    </Form.Item>
                  </div>
                </Form>
              </div>
            </Card>
            <Card style={{ marginBottom: customStyles.margin }}>
            <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: customStyles.margin }}>
              <Button type="primary" onClick={toggleServiceAccountTokenModal}>Create Token</Button>
            </div>
            {
              loading ? <Spinners/>
              :
              <div  style={{overflowX:'auto', whiteSpace:'nowrap'}}>
                <Table columns={columns} dataSource={(tokens || []).map((token, index) => ({
                      ...token,
                      key: index,
                  }))}
                  pagination={{
                    hideOnSinglePage: true,
                    pageSize: 10,
                    total: tokens.length,
                  }}
                />
              </div>
            }
            </Card>
          </Col>
        </Row>
      </div>
      <ToastContainer />
      <Modal centered title="Create Token" open={createServiceAccountTokenModalShow} onCancel={toggleServiceAccountTokenModal} footer={[]}>
        {token? (
          <div style={{ marginBottom: customStyles.margin }}>
            <label style={{ marginBottom: "4px", display: "block" }}>Token</label>
            <div>
              <p style={{ fontSize: "12px", fontStyle: "italic", marginBottom: "4px" }}>Copy the token now as you will not be able to see it again. Losing a token requires creating a new one.</p>
            </div>
            <Space.Compact style={{ width: '100%', marginBottom: customStyles.margin}}>
              <Input value={token.key} readOnly style={{ boxShadow: "none" }}/>
              <CopyToClipboard text={token.key} onCopy={handleCopyToClipboard}>
                  <Button type="primary" icon={<CopyOutlined />}/>
              </CopyToClipboard>
            </Space.Compact>
            <div style={{ display:'flex', justifyContent: "end" }}>
              <Button onClick={toggleServiceAccountTokenModal}>Close</Button>
            </div>
          </div>
        ) : (
          <Form  form={createServiceAccountTokenForm} name="create-token" onFinish={createServiceAccountTokenSubmit}>       
            <div>
              <label style={{ marginBottom: "4px", display: "block" }}>Name</label>
              <Form.Item name="name" rules={[{ required: true }]}>
                <Input name="name" type="text" placeholder="Enter name" style={{boxShadow: 'none'}}/>
              </Form.Item>
            </div>
            <div>
              <label style={{ marginBottom: "4px", display: "block" }}>Expiration</label>
              <Form.Item>
                <Radio.Group value={expiration} options={["No expiration", "Set expiration date"]} onChange={onExpirationChange} defaultValue={"No expiration"} buttonStyle="solid" optionType="button"/>
              </Form.Item>
            </div>
            {expiration === "Set expiration date" && (
              <div>
                <label style={{ marginBottom: "4px", display: "block" }}>Expiration date</label>
                <Form.Item name="secondsToLive" rules={[{ required: true }]}>
                  <DatePicker name="secondsToLive" />
                </Form.Item>
              </div>
            )}
            <div style={{ display:'flex', gap: '10px', justifyContent: "end" }}>
              <Button type="link" onClick={toggleServiceAccountTokenModal} danger>Close</Button>
              <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }} loading={loading}>Update</Button>
            </div>
          </Form>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default withRouter(ServiceAccountPage);
