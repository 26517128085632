import { getFakeUsers, removeFakeUser, enableFakeUser, disableFakeUser } from "../../../helpers/fakebackend_helper";
import { usersSuccess, setLoading } from "./reducer";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getUsers = (query) => async (dispatch) => {
    try {
        let response;
        dispatch(setLoading(true));
        response = getFakeUsers(query);
        const data = await response;
        if (data) {
          dispatch(usersSuccess(data));
        }
    } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to get users: " + error.message, { autoClose: 3000 });
    }
};

export const removeUser = (id) => async (dispatch) => {
  try {
      let response;
      response = removeFakeUser(id);
      const data = await response;
      if (data) {
          const query = {page: 1, perpage: 10, query: ''};
          dispatch(getUsers(query));
          toast.success("Removed user successfully", { autoClose: 3000 });
      }
  } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to remove user: " + error.message, { autoClose: 3000 });
  }
}

export const disableUser = (id) => async (dispatch) => {
  try {
      let response;
      response = disableFakeUser(id);
      const data = await response;
      if (data) {
          const query = {page: 1, perpage: 10, query: ''};
          dispatch(getUsers(query));
          toast.success("Disabled user successfully", { autoClose: 3000 });
      }
  } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to disable user: " + error.message, { autoClose: 3000 });
  }
}

export const enableUser = (id) => async (dispatch) => {
  try {
      let response;
      response = enableFakeUser(id);
      const data = await response;
      if (data) {
          const query = {page: 1, perpage: 10, query: ''};
          dispatch(getUsers(query));
          toast.success("Enabled user successfully", { autoClose: 3000 });
      }
  } catch (error) {
      dispatch(setLoading(false));
      toast.error("Failed to enable user: " + error.message, { autoClose: 3000 });
  }
}