import { debounce } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { createSelector } from "reselect";
import { styled } from 'styled-components';
import { ToastContainer } from 'react-toastify';

import { Button, Card, Col, Input, Pagination, Progress, Row, Tag, Typography, Modal, Form, Radio, Switch, Popconfirm, Badge } from 'antd';
import { MailOutlined, StopOutlined } from '@ant-design/icons';
import { Pen, Search, Trash2, UserPlus } from 'lucide-react';

import Breadcrumb from '../../Common/Breadcrumb';
import Spinners from '../../Common/Spinner';
import { roles } from "../../Common/data";
import usecustomStyles from '../../Common/customStyles';
import { bootData } from "../../config";

import { getCurrentOrgUsers, inviteUser, getOrgInvites, removeCurrentOrgUser, updateCurrentOrgUser } from "../../slices/thunk";

const customStyles = usecustomStyles();
const TextMuted = styled.div`
  color: ${({ theme }) => theme.token.textMute};
`;
const { Text } = Typography;

const CurrentOrgUsersPage = () => {
  document.title = "Users-" + bootData.settings.appTitle;
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [updatingUser, setUpdatingUser] = useState({});
  //Modals
  const [addUserModalShow, setAddUserModalShow] = useState(false)
  const toggleAddUserModal = useCallback(() => (addUserModalShow)? setAddUserModalShow(false): setAddUserModalShow(true), [addUserModalShow]);
  const [userRoleModalShow, setUserRoleModalShow] = useState(false);
  const updateUser = useCallback((user) => {
    setUserRoleModalShow(!userRoleModalShow);
    setUpdatingUser(user);
  }, [userRoleModalShow]);
    
  const selectUsers = createSelector(
    (state) => state.CurrentOrgUsers,
    (state) => state.OrgInvites,
    (currentOrgUsers, orgInvites) => ({
        users: currentOrgUsers.users,
        totalCount: currentOrgUsers.totalCount,
        page: currentOrgUsers.page,
        perPage: currentOrgUsers.perPage,
        loading: currentOrgUsers.loading,
        showInvites: orgInvites.showInvites,
    })
  );
  const { users, totalCount, page, perPage, loading, showInvites } = useSelector(selectUsers);

  useEffect(() => {
    const query = {page: 1, perpage: 10, query: '', accesscontrol: false};
      dispatch(getCurrentOrgUsers(query));
      dispatch(getOrgInvites());
  }, [dispatch]);

  const onPagination = (page, pageSize) => {
    const query = {page: page, perpage: pageSize, query: search, accesscontrol: false};
    dispatch(getCurrentOrgUsers(query));
  }
  const searchUser = (e) => {
    setSearch(e.target.value);
    const query = {page: 1, perpage: 10, query: e.target.value, accesscontrol: false};
    dispatch(getCurrentOrgUsers(query));
  }
  const delayedSearchUser = debounce((e) => searchUser(e), 500);
    
  //Initialise Forms
  const [addUserForm] = Form.useForm();
  const [userRoleForm] = Form.useForm();
  useEffect(() => {
    addUserForm.setFieldsValue({
      name: '',
      loginOrEmail: '',
      role: 'Viewer',
      sendEmail: false
    });
  }, [addUserForm]);
  useEffect(() => {
    userRoleForm.setFieldsValue({
      role: updatingUser.role || 'Viewer',
    });
  }, [userRoleForm, updatingUser]);

  //Submit Forms
  const handleAddUserSubmit = (values) => {
    dispatch(inviteUser(values));
    addUserForm.resetFields();
    toggleAddUserModal();
  }
  const handleRoleSubmit = (values) => {
    dispatch(updateCurrentOrgUser({...values, id: updatingUser.userId}));
    updateUser({});
    userRoleForm.resetFields();
  }

  return (
    <React.Fragment>
      <Breadcrumb mainTitle="Organization" pageTitle="Users" />
        <Row justify="center" align="middle" gutter={[24]}>
          <Col lg={24} xl={24}>
            <Card style={{ marginBottom: customStyles.margin }} >
              <div style={{ display: "flex", justifyContent: "space-between", gap: '10px' }}>
                <div className="search-box" style={{ position: "relative", display: "flex", width: '100%' }}>
                  <Input type="text" allowClear placeholder="Search for name or designation..." style={{ paddingLeft: "40px" }} onChange={(e) => delayedSearchUser(e)}/>
                  <Search style={{ position: "absolute", left: "10px", top: "10px" }} size={14} />
                </div>
                { showInvites && (
                  <div>
                    <Button type='primary' href='/org/users/invite'  style={{ backgroundColor: customStyles.colorPrimary, boxShadow:'none' }}>
                      <MailOutlined />Invites
                    </Button>
                  </div>)
                }
                <div>
                  <Button type='primary' onClick={toggleAddUserModal} style={{ backgroundColor: customStyles.colorPrimary, boxShadow:'none' }}>
                    <UserPlus size={16}/>Add User
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        {
          loading ? <Spinners/>
        : 
          <Row gutter={[24]} style={{ display: "flex", justifyContent: "flex-start" }}>
            {users.map((item, key) => (
              <Col xs={24} xl={6} key={key}>
                <Card style={{ marginBottom: customStyles.margin, position:'relative'}} >
                  <>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Popconfirm title="Delete user" description="Are you sure to delete?" onConfirm={() => dispatch(removeCurrentOrgUser(item.userId))}  okText="Delete" cancelText="Cancel">
                        <Button icon={<Trash2 size={12} />} size="small" danger/>
                      </Popconfirm>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div>
                        {item.isDisabled ? (
                          <Badge count={ <StopOutlined style={{ fontSize: '18px', color: customStyles.colorDanger, borderRadius: "50%" }} />} >
                            <img src={item.avatarUrl} alt="" style={{ opacity: 0.5, height: "72px", width: "72px", borderRadius: "5px", marginBottom: customStyles.margin }} />
                          </Badge>
                        ) : (
                          <img src={item.avatarUrl} alt="" style={{ height: "72px", width: "72px", borderRadius: "5px", marginBottom: customStyles.margin }} />
                        )}
                      </div>
                    </div>
                    <div className="text-center" style={{ marginBottom: customStyles.margin }}>
                      <Link>
                        <div>
                          <Text style={{ fontSize: "17px" }}>{item.name}</Text>
                        </div>
                        <div>
                          <TextMuted>{(item.login && item.login !== "")? item.login: item.email}</TextMuted>
                        </div>
                      </Link>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "baseline" }}>
                        <Tag bordered={false} color={(item.isDisabled) ? "error" : "success"} style={{ marginTop: customStyles.marginSM }}>{item.role}</Tag>
                        <Button onClick={() => updateUser(item)} icon={<Pen size={12}/>} size="small" />
                      </div>
                    </div>
                    <div>
                      <Progress percent={(item.isDisabled) ? 0 : 100} size="small" showInfo={false} />
                    </div>
                    {/* <Row style={{ marginTop: customStyles.marginSM, textAlign: "center", display: "flex", justifyContent: "space-around" }}>
                        <Col style={{ borderRight: "1px dashed #eff2f7", borderRightStyle: "dashed", padding:'0 24px' }}>
                            <Text style={{ marginBottom: customStyles.marginXXS, fontSize: customStyles.h4 }}>{item.projects}</Text>
                            <p style={{ marginTop: '10px', marginBottom:'0', color: themecolor.token.textMuted }}>Projects</p>
                        </Col>
                        <Col style={{ borderRight: "1px dashed #eff2f7", borderRightStyle: "dashed",  padding:'0 24px' }}>
                            <Text style={{ marginBottom: customStyles.marginXXS, fontSize: customStyles.h4 }}>{item.overdue}</Text>
                            <p style={{ marginTop: '10px', marginBottom:'0', color: themecolor.token.textMuted }}>Overdue</p>
                        </Col>
                        <Col style={{ padding:'0 24px'}}>
                            <Text style={{ marginBottom: customStyles.marginXXS, fontSize: customStyles.h4 }}>{item.tasks}</Text>
                            <p style={{ marginTop: '10px', marginBottom:'0', color: themecolor.token.textMuted }}>Tasks</p>
                        </Col>
                    </Row> */}
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Link>
                        <div>
                          <TextMuted style={{ fontSize: "8px" }}>{"lastseen: "}{item.lastSeenAtAge }{" ago"}</TextMuted>
                        </div>
                      </Link>
                    </div>
                  </>
                </Card>
              </Col>
            ))}
            <Col span={24}>
              <Pagination defaultCurrent={1} current={page} pageSize={perPage} total={totalCount} onChange={onPagination} style={{ marginBottom: customStyles.margin, float: "right" }} />
            </Col>
          </Row>
        }
        <Modal centered title="Add User" open={addUserModalShow} onCancel={toggleAddUserModal} footer={[]}>
          <Form  form={addUserForm} name="add-user" onFinish={handleAddUserSubmit}>
            <div>
              <label style={{ display: 'block', marginBottom: '4px' }}>Name</label>
              <Form.Item name="name" rules={[{ required: true, message: 'Name is required' }]}>
                <Input name="name" type="text" placeholder="Enter name" style={{boxShadow: 'none'}}/>
              </Form.Item>
            </div>
            <div>
              <label style={{ display: 'block', marginBottom: '4px' }}>Login/Email</label>
              <Form.Item name="loginOrEmail" rules={[{ required: true, message: 'Login/Email is required' }]}>
                <Input name="loginOrEmail" type="text" placeholder="Enter login/email" style={{boxShadow: 'none'}}/>
              </Form.Item>
            </div>
            <div>
              <label style={{ display: 'block', marginBottom: '4px' }}>Role</label>
              <Form.Item name="role" initialValue="Viewer" rules={[{ required: true, message: 'Role is required' }]}>
                <Radio.Group name="role" defaultValue="Viewer" options={roles} buttonStyle="solid" optionType="button"/>
              </Form.Item>
            </div>
            <div>
              <label style={{ display: 'block', marginBottom: '4px' }}>Send Email</label>
              <Form.Item name="sendEmail" initialValue={false} valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div style={{ display:'flex', gap: '10px', justifyContent: "end", marginTop: customStyles.margin }}>
              <Form.Item>
                <Button type="link" danger onClick={() => toggleAddUserModal()}>Close</Button>
                <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }}>Invite</Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>
        <Modal centered title="Update Role" open={userRoleModalShow} onCancel={() => updateUser({})} footer={[]}>
          <Form  form={userRoleForm} name="update-role" onFinish={handleRoleSubmit}>       
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: customStyles.margin }}>
              <Form.Item name="role" rules={[{ required: true, message: 'Role is required' }]}>
                <Radio.Group name="role" options={roles} buttonStyle="solid" optionType="button"/>
              </Form.Item>
            </div>
            <div style={{ display:'flex', gap: '10px', justifyContent: "end" }}>
              <Button type="link" danger onClick={() => updateUser({})}>Close</Button>
              <Button htmlType="submit" style={{ backgroundColor: customStyles.colorPrimary, color: "white" }}>Update</Button>
            </div>
          </Form>
        </Modal>
        <ToastContainer />
    </React.Fragment>
  )
}

export default CurrentOrgUsersPage